import { useState } from "react";
import { useAuth } from "../AuthProvider";
import UpdateUserDetails from "./UpdateUserDetails";
import VerifyOTP from "./VerifyOTP";

const UserRegistration = ({ data, next, auth }) => {
  const [otpVerified, setOtpVerified] = useState(false);
  const { token } = useAuth();

  if (auth && !token) {
    return <div>Authentication error</div>;
  }

  if (!auth && (!otpVerified || !token.current)) {
    return <VerifyOTP data={data} next={() => setOtpVerified(true)} />;
  }

  return (
    <UpdateUserDetails
      auth={auth}
      data={data}
      next={() => {
        next();
      }}
    />
  );
};

export default UserRegistration;
