import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiInfoCircle } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";
import MaskedInput from "react-text-mask";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";
import { useAuth } from "../AuthProvider";
import CustomStyledSelect from "../CustomStyledSelect";
import { BsFilePdfFill } from "react-icons/bs";
import UploadTypeFormField from "./UploadTypeFormField";
import { uploadToS3 } from "@/utilities/s3Helpers";
import { useParams } from "react-router-dom";

const UpdateUserDetails = ({ data, next, auth }) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const [isUploading, setIsUploading] = useState(false);
  const { test_id } = useParams();
  const { isLoading, data: participantData } = useQuery(
    ["participant", "welcome"],
    async () => {
      try {
        const { data } = await axios.get(
          `${FUNCTION_BASE_URL}/getParticipantInfo?test_id=${test_id}`,
          {
            headers: {
              Authorization: `Bearer ${token.current}`,
            },
            timeout: 30000,
          }
        );
        return data;
      } catch (error) {
        throw new Error(error.response.data.error.message);
      }
    },
    {
      enabled: Boolean(token),
    }
  );
  const schema = useMemo(() => {
    return Yup.object().shape({
      ...(data.registration_form_fields?.fullName?.isRequired
        ? {
            fullName: Yup.string()
              .nullable()
              .required("Full Name is required."),
          }
        : {}),
      ...(data.registration_form_fields?.gender?.isRequired
        ? {
            gender: Yup.string().nullable().required("Gender is required."),
          }
        : {}),
      ...(data.registration_form_fields?.phone_number?.isRequired
        ? {
            phoneNumber: Yup.string()
              .nullable()
              .required("Phone Number is required.")
              .matches(/\+91[1-9][\d]{9}$/, "Invalid Phone Number"),
          }
        : {}),
      ...(data.registration_form_fields?.resume?.isRequired
        ? {
            resume: Yup.string().nullable().required("Resume is required."),
          }
        : {}),

      ...(data.registration_form_fields?.extraFields ?? []).reduce(
        (prev, cur) => {
          if (cur?.isRequired) {
            return {
              ...prev,
              [cur.name]: Yup.string().nullable().required("Required"),
            };
          }
          return prev;
        },
        {}
      ),
    });
  }, [data]);

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = methods;
  const values = watch();
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { "application/pdf": [".pdf"] },
      multiple: false,
      maxSize: 2000000,
      onDropRejected: (files) => {
        toast.error(files[0].errors[0].message);
      },
      onDropAccepted: async (files) => {
        setIsUploading(true);
        try {
          const resData = await uploadToS3({
            file: files[0],
            path: "resume.pdf",
            token: token.current,
            type: "application/pdf",
          });

          setIsUploading(false);
          setValue("resume", resData.path);
        } catch (error) {
          setIsUploading(false);
        }
      },
      disabled: isUploading,
    });

  useEffect(() => {
    if (data && !data.require_registration) {
      next();
    }
  }, [data, next]);

  useEffect(() => {
    if (participantData) {
      reset({
        email: participantData?.test_participant?.user?.email ?? "",
        fullName: participantData?.test_participant?.user?.fullName ?? "",
        resume: participantData?.test_participant?.user?.resume ?? "",
        gender: participantData?.test_participant?.user?.gender ?? "",
        phoneNumber:
          participantData?.test_participant?.user?.phone_number ?? "",
        ...(participantData?.test_participant?.registrationData ?? {}),
      });
    }
  }, [participantData, reset, auth]);
  const agreeTerms = watch("agreeTerms");
  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async (values) => {
      const { data: resData } = await axios.post(
        `${FUNCTION_BASE_URL}/updateCandidateDetails?test_id=${test_id}`,
        {
          ...(data.registration_form_fields?.fullName
            ? {
                fullName: values.fullName,
              }
            : {}),
          ...(data.registration_form_fields?.gender
            ? {
                gender: values.gender,
              }
            : {}),
          ...(data.registration_form_fields?.phone_number
            ? {
                phoneNumber: values.phoneNumber,
              }
            : {}),
          ...(data.registration_form_fields?.resume
            ? {
                resume: values.resume,
              }
            : {}),

          tenantId: data.university?.tenantId,
          test_id: data.id,
          ...(data.registration_form_fields?.extraFields ?? []).reduce(
            (prev, cur) => {
              return {
                ...prev,
                [cur.name]: values[cur.name],
              };
            },
            {}
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${token.current}`,
          },
          timeout: 30000,
        }
      );

      return resData;
    },
    {
      onError: (error) => {
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onMutate: async (variables) => {
        if (variables) {
          try {
            await queryClient.cancelQueries(["assessment", "attempt"]);
          } catch (e) {
            console.error(e);
          }
        }
      },
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        } else {
          next();
        }
      },
    }
  );

  if (!token.current) {
    return <div>Authentication Error</div>;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <FormProvider {...methods}>
      <div className="flex flex-col w-full gap-5 px-8 py-8 text-base lg:pr-12 lg:pl-0">
        <div className="flex items-center w-full gap-3 px-5 py-2 rounded bg-slate-100 text-slate-800">
          <BiInfoCircle className="w-5 h-5" />
          <div className="font-medium">Fields marked with * are compulsory</div>
        </div>

        <div className="flex flex-col border rounded-md shadow">
          <div className="flex items-center w-full gap-3 px-5 rounded bg-slate-100 text-slate-800">
            <div className="h-full px-2 py-2 font-medium border-b-4 border-slate-800">
              Registration Details
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5 p-5"
          >
            <>
              {data.registration_form_fields?.fullName && (
                <div>
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name{" "}
                    {data.registration_form_fields?.fullName?.isRequired && (
                      <span className="text-red-700">*</span>
                    )}
                  </label>
                  <div className="mt-1">
                    <input
                      id="fullName"
                      name="fullName"
                      required
                      {...register("fullName", {
                        required: "required",
                      })}
                      className={twMerge(
                        "block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none bg-slate-50 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                        errors.fullName ? "border-red-500" : ""
                      )}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    as="div"
                    className="text-sm text-red-600"
                    name={`fullName`}
                  />
                </div>
              )}
              {data.registration_form_fields?.gender && (
                <div>
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gender{" "}
                    {data.registration_form_fields?.gender?.isRequired && (
                      <span className="text-red-700">*</span>
                    )}
                  </label>
                  <div className="mt-1">
                    <CustomStyledSelect
                      control={control}
                      name={"gender"}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                        { label: "Prefer Not to say", value: "na" },
                      ]}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    as="div"
                    className="text-sm text-red-600"
                    name={`gender`}
                  />
                </div>
              )}

              {data.registration_form_fields?.phone_number && (
                <div>
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number{" "}
                    {data.registration_form_fields?.phone_number
                      ?.isRequired && <span className="text-red-700">*</span>}
                  </label>
                  <div className="mt-1">
                    <Controller
                      name={"phoneNumber"}
                      control={control}
                      render={({ field: { value, onChange, name } }) => {
                        return (
                          <MaskedInput
                            placeholder="Phone Number"
                            onChange={onChange}
                            value={value}
                            mask={[
                              "+",
                              "9",
                              "1",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            maxLength={14}
                            max={14}
                            showMask={true}
                            guide={false}
                            autoComplete="tel"
                            name={name}
                            className={twMerge(
                              "block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none bg-slate-50 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                              errors.phoneNumber ? "border-red-500" : ""
                            )}
                          />
                        );
                      }}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    as="div"
                    className="text-sm text-red-600"
                    name={`phoneNumber`}
                  />
                </div>
              )}
              {data.registration_form_fields?.resume && (
                <div>
                  {!values?.resume && (
                    <>
                      <div className="py-2 mb-2">
                        Please upload your resume here to continue{" "}
                        {data.registration_form_fields?.resume?.isRequired && (
                          <span className="text-red-700">*</span>
                        )}
                      </div>
                      <div
                        {...getRootProps({
                          className:
                            "dropzone rounded-md border border-dashed border-gray-400 focus:outline-none",
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className="flex flex-col items-center justify-center p-5 ">
                          <div className="text-center">
                            {isDragActive ? (
                              <p>Drop here ...</p>
                            ) : (
                              <p>{"Drag 'n' drop here"}</p>
                            )}
                          </div>
                          <div className="">
                            <FaPlusCircle className="w-6 h-6" />
                          </div>
                        </div>
                      </div>
                      <div className="py-2 text-sm text-right">
                        *Please note: Only PDF file less than 2 MB is accepted
                      </div>
                      {isUploading ? (
                        <ul>
                          {acceptedFiles.map((file) => (
                            <li key={file.path} className="flex items-center">
                              <div>{file.path}</div>
                              <div className="w-6 h-6 mr-3 spinner-grow"></div>
                              <div>Uploading</div>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </>
                  )}

                  {values?.resume && (
                    <div className="flex flex-col max-w-screen-md gap-2">
                      <div className="">
                        Resume
                        {data.registration_form_fields?.resume?.isRequired && (
                          <span className="text-red-700">*</span>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <span>
                          <BsFilePdfFill className="w-8 h-8 text-red-600" />
                        </span>
                        <span>resume.pdf</span>
                        <button
                          onClick={() => {
                            setValue("resume", "");
                          }}
                          className="text-blue-600 cursor-pointer"
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  )}
                  <ErrorMessage
                    errors={errors}
                    as="div"
                    className="text-sm text-red-600"
                    name={`resume`}
                  />
                  {/* {data.users[0].resume && (
                  <div className="max-w-screen-md">
                    <FileViewerComponent
                      type={"pdf"}
                      url={data.users[0].resume}
                    />
                  </div>
                )} */}
                </div>
              )}
              {(data.registration_form_fields?.extraFields ?? []).map(
                (field) => {
                  switch (field?.type) {
                    case "upload":
                      return (
                        <UploadTypeFormField
                          field={field}
                          name={field.name}
                          data={data}
                        />
                      );
                    case "radio":
                    case "select":
                      return (
                        <div className="w-full mt-3" key={field.id}>
                          <label
                            htmlFor={field?.name}
                            className="block text-sm font-medium text-gray-700"
                          >
                            {field?.label ?? field?.name}
                            {field.isRequired && (
                              <span className="text-red-700">*</span>
                            )}
                          </label>

                          <CustomStyledSelect
                            placeholder={field?.label ?? field?.name}
                            name={field?.name}
                            options={(field?.options ?? []).map((el) => ({
                              value: el,
                              label: el,
                            }))}
                            control={control}
                          />

                          <ErrorMessage
                            errors={errors}
                            as="div"
                            className="text-sm text-red-600"
                            name={field?.name}
                          />
                        </div>
                      );

                    default:
                      return (
                        <div>
                          <label
                            htmlFor={field?.name}
                            className="block text-sm font-medium text-gray-700"
                          >
                            {field?.label ?? field?.name}
                            {field.isRequired && (
                              <span className="text-red-700">*</span>
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              id={field?.name}
                              name={field?.name}
                              {...register(field?.name, {
                                required: "required",
                              })}
                              className={twMerge(
                                "block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none bg-slate-50 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                                errors[field?.name] ? "border-red-500" : ""
                              )}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            as="div"
                            className="text-sm text-red-600"
                            name={field?.name}
                          />
                        </div>
                      );
                  }
                }
              )}
              <div>
                <label
                  htmlFor="agreeTerms"
                  className="block gap-2 text-sm font-medium text-gray-700"
                >
                  Terms and Conditions <span className="text-red-700">*</span>
                </label>
                <div className="flex items-start mt-2">
                  <div className="flex items-center h-6">
                    <input
                      id="agreeTerms"
                      name="agreeTerms"
                      {...register("agreeTerms", {
                        required: true,
                      })}
                      type="checkbox"
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="agreeTerms" className="text-gray-900">
                      By using our offerings and services, you are agreeing to
                      the Terms of Services and License Agreement and understand
                      that your use and access will be subject to the terms &
                      conditions and Privacy Notice contained therein.
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  as="div"
                  className="text-sm text-red-600"
                  name={`agreeTerms`}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className={twMerge(
                    `w-full flex items-center justify-center gap-3 px-5 py-2 text-sm text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                    isSubmitting || !agreeTerms
                      ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
                      : ""
                  )}
                  disabled={isSubmitting || !agreeTerms}
                >
                  {isSubmitting ? (
                    <ArrowPathIcon className="w-5 h-5 animate-spin" />
                  ) : null}
                  {isSubmitting ? "Submitting" : "Submit"}
                </button>
              </div>
            </>
          </form>
        </div>
      </div>
    </FormProvider>
  );
};

export default UpdateUserDetails;
