import { AuthProvider } from "@/components/AuthProvider";
import SignInViaToken from "@/pages/signInViaToken";
import AllRoutes from "@/routes";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import {
  addSeconds,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
} from "date-fns";
import { useEffect, useMemo } from "react";
import { ToastBar, Toaster, toast } from "react-hot-toast";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MdClose } from "react-icons/md";
import ErrorBoundary from "./components/ErrorBoundary";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 0,
      networkMode: "online",
      retry: 1,
    },
  },
});
function CheckSystemTime() {
  const { isLoading, data } = useQuery(
    ["servertime"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/servertime`, {})
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        }),
    {
      refetchInterval: 30000,
    }
  );
  const difference = useMemo(() => {
    if (data) {
      return Math.abs(differenceInSeconds(new Date(data.now), new Date()));
    }
    return 0;
  }, [data]);

  if (isLoading || difference < 180) return null;
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {}}
      htmlOpenClassName="overflow-hidden"
      bodyOpenClassName="overflow-hidden"
      closeTimeoutMS={300}
      className="inset-x-auto inset-y-auto flex flex-col max-h-full rounded-md focus:outline-none"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-90 bg-black inset-0 fixed p-8 z-50"
    >
      <div className="flex flex-col w-64 max-h-full px-5 py-6 overflow-y-auto bg-white sm:px-12 md:w-110 rounded-b-md">
        <div className=""></div>
        <div className="w-full text-xl font-semibold text-center">
          Invalid System Time
        </div>
        <div className="text-sm text-center">
          {"(Please check your system time and make sure it's set correctly)"}
        </div>
        {data && (
          <div className="flex flex-col mt-5 text-sm">
            <div>
              Server Time:{" "}
              <span className="font-semibold">
                {format(new Date(data.now), "Ppp")}
              </span>
            </div>
            <div>
              System time:{" "}
              <span className="font-semibold">{format(new Date(), "Ppp")}</span>
            </div>
            <div>
              Found difference of{" "}
              <span className="font-semibold">
                {formatDistanceToNowStrict(addSeconds(new Date(), difference))}
              </span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
function ScrollToTop() {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CheckSystemTime />
      <ScrollToTop />
      <SentryRoutes>
        <Route
          path="signInViaToken"
          element={
            <ErrorBoundary>
              <SignInViaToken />
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <AuthProvider>
              <AllRoutes />
            </AuthProvider>
          }
        />
      </SentryRoutes>
      <ReactQueryDevtools initialIsOpen={false} />
      {/* <Toaster position="top-center" />
       */}
      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="flex items-center h-full pl-2 border-l"
                  >
                    <MdClose className="w-4 h-4" />
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </QueryClientProvider>
  );
}

export default App;
