import app from "@/config/firebase";
import { getAuth } from "firebase/auth";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const AuthContext = createContext({
  user: null,
  token: null,
  setToken: () => {},
});

export function AuthProvider({ children }) {
  //   const [user, setUser] = useState(null)
  const token  = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const authApp = getAuth(app);
      authApp.tenantId = localStorage.getItem("tenantId");
      if (authApp.currentUser) {
        const token1 = await authApp.currentUser.getIdToken(true);
        token.current = token1
      }
    }, 1800000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = getAuth(app).onAuthStateChanged(
      async (user) => {
        try {
          if (user) {
            const token1 = await user.getIdToken(true);
            token.current = token1
          }
        } catch (error) {
          console.log("error", error);
        }
        setLoading(false);
      },
      () => {}
    );
    return () => {
      unsubscribe();
    }; // eslint-disable-next-line
  }, []);
  return (
    <AuthContext.Provider
      value={{ token, currentUser: getAuth(app).currentUser,  }}
    >
      {loading ? (
        <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
          <div className="w-6 h-6 spinner-grow" />
          <div className="ml-3">Loading</div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
