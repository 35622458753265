import { useAuth } from "@/components/AuthProvider";
import { getFromS3 } from "@/utilities/s3Helpers";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { RiPlayCircleLine } from "react-icons/ri";

function AudioS3({ path }) {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  if (url) {
    return (
      <audio controls controlsList="nodownload">
        <source src={url} />
        Your browser does not support the audio tag.
      </audio>
    );
  }
  return (
    <button
      className="flex items-center justify-center gap-3 px-5 py-2 text-white bg-indigo-600 rounded-md"
      onClick={async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          const data = await getFromS3({
            path,
            token: token.current,
          });
          setUrl(data.url);
          setLoading(false);
        } catch (error) {
          console.log("error", error);
          toast.error("Failed To Play");
          setLoading(false);
        }
      }}
    >
      {loading ? (
        <ArrowPathIcon className="w-5 h-5 animate-spin" />
      ) : (
        <RiPlayCircleLine className="w-5 h-5" />
      )}
      {loading ? "Loading..." : "Play Audio"}
    </button>
  );
}

export default AudioS3;
