import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePermission } from "react-use";
import { toast } from "react-hot-toast";
import createWaveFileBlobFromAudioBuffer from "./exporter.js";
import throttle from "lodash/throttle.js";

const RecorderStates = {
  UNINITIALIZED: 0,
  RECORDING: 1,
  PAUSED: 2,
  FINISHED: 3,
};

const RecorderErrors = {
  AbortError: "media_aborted",
  NotAllowedError: "permission_denied",
  NotFoundError: "no_specified_media_found",
  NotReadableError: "media_in_use",
  OverconstrainedError: "invalid_media_constraints",
  TypeError: "no_constraints",
  NONE: "",
  NO_RECORDER: "recorder_error",
};
export function ReactAudioRecorder({
  onStop = () => null,
  onPause = () => null,
  onResume = () => null,
  onStart = () => null,
  mediaRecorderOptions = null,
  askPermissionOnMount = false,
  children,
}) {
  const [duration, setDuration] = useState(0);
  const [recordingState, setRecordingState] = useState(
    RecorderStates.UNINITIALIZED
  );
  const [url, setUrl] = useState(null);
  const recordingNodeRef = useRef(null);
  const [context, setContext] = useState(() => {
    return new AudioContext();
  });
  const [stopCallback, setStopCallback] = useState(null);
  const microphone = usePermission({ name: "microphone" });
  const setupRecordingWorkletNode = useCallback(
    async (recordingProperties) => {
      await context.audioWorklet.addModule("/recording-processor.js");

      const WorkletRecordingNode = new AudioWorkletNode(
        context,
        "recording-processor",
        {
          processorOptions: recordingProperties,
        }
      );

      return WorkletRecordingNode;
    },
    [context]
  );

  const createRecord = useCallback(
    (recordingProperties, recordingLength, sampleRate, dataBuffer) => {
      const recordingBuffer = context.createBuffer(
        recordingProperties.numberOfChannels,
        recordingLength,
        sampleRate
      );

      for (let i = 0; i < recordingProperties.numberOfChannels; i++) {
        recordingBuffer.copyToChannel(dataBuffer[i], i, 0);
      }

      const blob = createWaveFileBlobFromAudioBuffer(recordingBuffer, true);
      setUrl(window.URL.createObjectURL(blob));
      onStop(blob, duration);
      //   player.src = audioFileUrl;
      //   downloadLink.href = audioFileUrl;
      //   downloadLink.download = `recording-${new Date()
      //     .getMilliseconds()
      //     .toString()}.wav`;
      //   downloadButton.disabled = false;
    },
    [context, onStop, duration]
  );
  const throttledSetDuration = useCallback(
    throttle(
      (recordingLength) =>
        setDuration(
          Math.round((recordingLength / context.sampleRate) * 100) / 100
        ),
      500
    ),
    []
  );

  const handleRecording = useCallback(
    (processorPort, recordingProperties) => {
      let recordingLength = 0;

      // If the max length is reached, we can no longer record.
      const recordingEventCallback = async (event) => {
        console.log("event.data.message", event.data.message);
        if (event.data.message === "MAX_RECORDING_LENGTH_REACHED") {
          setRecordingState(RecorderStates.FINISHED);
          createRecord(
            recordingProperties,
            recordingLength,
            context.sampleRate,
            event.data.buffer
          );
        }
        if (event.data.message === "UPDATE_RECORDING_LENGTH") {
          recordingLength = event.data.recordingLength;
          throttledSetDuration(recordingLength);
        }
        if (event.data.message === "SHARE_RECORDING_BUFFER") {
          createRecord(
            recordingProperties,
            recordingLength,
            context.sampleRate,
            event.data.buffer
          );
        }
      };

      if (recordingState === RecorderStates.UNINITIALIZED) {
        setRecordingState(RecorderStates.RECORDING);
        processorPort.postMessage({
          message: "UPDATE_RECORDING_STATE",
          setRecording: true,
        });
      }

      return recordingEventCallback;
    },
    [context, recordingState, createRecord, throttledSetDuration]
  );

  const initializeAudio = useCallback(async () => {
    if (context.state === "suspended") {
      await context.resume();
    }

    // Get user's microphone and connect it to the AudioContext.
    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: false,
        autoGainControl: false,
        noiseSuppression: false,
        latency: 0,
      },
    });

    const micSourceNode = new MediaStreamAudioSourceNode(context, {
      mediaStream: micStream,
    });
    const gainNode = new GainNode(context);
    // const analyserNode = new AnalyserNode(context);

    const recordingProperties = {
      numberOfChannels: micSourceNode.channelCount,
      sampleRate: context.sampleRate,
      maxFrameCount: context.sampleRate * 300,
    };

    recordingNodeRef.current = await setupRecordingWorkletNode(
      recordingProperties
    );

    // We can pass this port across the app and let components handle
    // their relevant messages.
    const recordingCallback = handleRecording(
      recordingNodeRef.current.port,
      recordingProperties
    );

    recordingNodeRef.current.port.onmessage = (event) => {
      if (event.data.message === "UPDATE_VISUALIZERS") {
        // TODO
        //   visualizerCallback(event);
      } else {
        recordingCallback(event);
      }
    };

    gainNode.gain.value = 0;

    micSourceNode
      // .connect(analyserNode)
      .connect(recordingNodeRef.current)
      .connect(gainNode)
      .connect(context.destination);

    recordingNodeRef.current.port.postMessage({
      message: "UPDATE_RECORDING_STATE",
      setRecording: true,
    });

    setStopCallback(() => {
      return () => {
        setRecordingState(RecorderStates.FINISHED);
        // Clear the buffers:
        recordingNodeRef.current.port.postMessage({
          message: "FLUSH",
        });
        if (micStream)
          micStream?.getAudioTracks().forEach((track) => track.stop());

        micSourceNode?.disconnect();
      };
    });
  }, [context, handleRecording, setupRecordingWorkletNode]);

  useEffect(() => {
    return () => {
      if (stopCallback) {
        stopCallback();
        if (context?.state !== "closed") {
          context?.close();
        }
      }
    };
  }, []);
  // const trigger = useCallback(
  //   (command, data) => {
  //     console.log("command, data", command, data);
  //     if (command === "wav-delivered") {
  //       const url = URL.createObjectURL(data);
  //       setMediaBlobUrl(url);
  //       onStop(url, data, durationRef.current);
  //       setStatus("stopped");
  //       pauseTimer();
  //     }
  //   },
  //   [onStop]
  // );

  // const getMediaStream = useCallback(async () => {
  //   setStatus("acquiring_media");

  //   try {
  //     const stream = await window.navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     });
  //     mediaStream.current = stream;
  //     setStatus("idle");
  //   } catch (error) {
  //     setError(error.name);
  //     setStatus("idle");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!window.MediaRecorder) {
  //     throw new Error("Unsupported Browser");
  //   }

  //   if (screen) {
  //     if (!window.navigator.mediaDevices.getDisplayMedia) {
  //       throw new Error("This browser doesn't support screen capturing");
  //     }
  //   }

  //   // const checkConstraints = (mediaType) => {
  //   //   const supportedMediaConstraints =
  //   //     navigator.mediaDevices.getSupportedConstraints();
  //   //   const unSupportedConstraints = Object.keys(mediaType).filter(
  //   //     (constraint) => !supportedMediaConstraints[constraint]
  //   //   );

  //   //   if (unSupportedConstraints.length > 0) {
  //   //     console.error(
  //   //       `The constraints ${unSupportedConstraints.join(
  //   //         ","
  //   //       )} doesn't support on this browser. Please check your ReactAudioRecorder component.`
  //   //     );
  //   //   }
  //   // };

  //   if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
  //     if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
  //       console.error(
  //         `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
  //       );
  //     }
  //   }

  //   if (!mediaStream.current && askPermissionOnMount) {
  //     getMediaStream();
  //   }
  // }, [getMediaStream, mediaRecorderOptions, askPermissionOnMount]);

  // Media Recorder Handlers

  const startRecording = async () => {
    if (microphone === "denied") {
      return toast.error("You haven't given the requrired permission.");
    }
    throttledSetDuration(0);
    initializeAudio();
    setUrl(null);
    onStart();

    if (recordingNodeRef.current) {
      setRecordingState(RecorderStates.RECORDING);
      recordingNodeRef.current.port.postMessage({
        message: "UPDATE_RECORDING_STATE",
        setRecording: true,
      });
    }
  };

  const stopRecording = async () => {
    if (recordingNodeRef.current) {
      recordingNodeRef.current.port.postMessage({
        message: "UPDATE_RECORDING_STATE",
        setRecording: false,
      });
    }
    if (stopCallback) {
      stopCallback();
    }
  };

  // const muteAudio = (mute) => {
  //   setIsAudioMuted(mute);
  //   if (mediaStream.current) {
  //     mediaStream.current
  //       .getAudioTracks()
  //       .forEach((audioTrack) => (audioTrack.enabled = !mute));
  //   }
  // };

  const pauseRecording = () => {
    if (recordingNodeRef.current) {
      setRecordingState(RecorderStates.PAUSED);
      recordingNodeRef.current.port.postMessage({
        message: "UPDATE_RECORDING_STATE",
        setRecording: false,
      });
    }
    onPause();
  };
  const resumeRecording = () => {
    setUrl(null);
    if (recordingNodeRef.current) {
      setRecordingState(RecorderStates.RECORDING);
      recordingNodeRef.current.port.postMessage({
        message: "UPDATE_RECORDING_STATE",
        setRecording: true,
      });
    }
    onResume();
  };

  return children({
    // muteAudio: () => muteAudio(true),
    // unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    recordingState,
    duration,
    url,
  });
}
