import { useAuth } from "@/components/AuthProvider";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { addMinutes } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

function ChooseSection() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const { test_id } = useParams();
  const { isLoading, error, data } = useQuery(
    ["assessment", "attempt_section"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/getSectionsForSelection?test_id=${test_id}`, {
        headers: {
          Authorization: `Bearer ${token.current}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        })
  );

  const next_section = useMemo(
    () =>
      (data?.test_sections ?? [])?.find((el) => !el.attempt?.is_completed)?.id,
    [data]
  );

  const { mutate: chooseSection, isLoading: submitting } = useMutation(
    async (values) => {
      try {
        const { data: resData } = await axios.post(
          `${FUNCTION_BASE_URL}/nextSection?test_id=${test_id}`,
          {
            test_section_id: values.test_section_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token.current}`,
            },
            timeout: 30000,
          }
        );
        return resData;
      } catch (error) {
        console.log("error", error);
        throw new Error(
          error?.response?.data?.error?.message ?? "Something went wrong"
        );
      }
    },
    {
      onError: (error) => {
        console.log("error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(error?.message ?? "Something went wrong ");
        }
      },
      onSuccess: () => {
        navigate(`/attempt/${data.test_attempt.id}`);
      },
    }
  );

  const { mutate: endTest, isLoading: endTestLoading } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/endTest?attempt_id=${data.test_attempt.id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (resData) => {
        if (resData?.error) {
          toast.error(resData?.error?.message || "Something went wrong");
        } else {
          navigate(`/attempt/${data.test_attempt.id}/complete`);
        }
      },

      onError: (error) => {
        console.log("endTest error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
    }
  );

  useEffect(() => {
    if (data && !data.error) {
      if (data.test_sections.every((el) => el.attempt?.is_completed)) {
        return endTest({
          test_attempt_id: data.test_attempt.id,
          rating: 0,
          ending_method: "user",
        });
      }
      // setSelected(
      //   next_section ?? data.test_sections[data.test_sections.length - 1]?.id
      // );
    }
  }, [data, next_section, endTest]);
  const timerEnd = useMemo(() => {
    return addMinutes(new Date(), 1);
  }, []);

  //Todo
  if (
    data &&
    !Object.prototype.hasOwnProperty.call(data, "error") &&
    !Object.prototype.hasOwnProperty.call(data, "test_attempt")
  ) {
    toast.error("No attempt found");
    return <Navigate to={`/welcome/${test_id}`} />;
  }

  if (data?.test_attempt?.is_completed) {
    return <Navigate to={`/attempt/${data.test_attempt.id}/complete`} />;
  }
  if (isLoading || endTestLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }
  if (!data || data.error) {
    throw new Error(data?.error);
    // return <div>Error... {data?.error?.message}</div>;
  }
  if (!token.current) {
    throw new Error("Invalid Token");
    // return <div>Invalid Token</div>;
  }
  return (
    <div className="flex flex-col w-full min-h-full gap-2 bg-slate-800">
      <header className="sticky top-0 px-16 py-5 text-lg text-white bg-slate-800">
        <div className="flex items-center gap-2">
          {data?.university?.icon && (
            <img
              src={data?.university?.icon}
              alt="Icon"
              className="object-contain w-8 h-8"
            />
          )}

          {data?.university?.name ?? "Meritcurve"}
        </div>
      </header>
      <main className="flex flex-col items-center justify-center flex-grow px-8">
        <div className="max-w-screen-md bg-white rounded-md">
          <div className="flex flex-col gap-5 p-8 text-base">
            <Countdown
              autoStart={true}
              daysInHours={true}
              // onTick={({ total }) => {
              //   console.log("total", total);
              // }}
              renderer={({ formatted }) => {
                const { seconds } = formatted;

                return (
                  <div className="flex items-center gap-3">
                    <div className="w-5 h-5 spinner-grow" />
                    <div className="font-medium">
                      Proceed to next section. {seconds} secs
                    </div>
                  </div>
                );
              }}
              date={timerEnd}
              onComplete={() => {
                chooseSection({
                  test_section_id: selected
                    ? selected
                    : next_section ??
                      data.test_sections[data.test_sections.length - 1]?.id,
                });
              }}
            />
            {data.test_sections.map((section, i) => {
              const disabled = !(
                !section.attempt?.is_completed &&
                ((data.strict_section_order && section.id === next_section) ||
                  !data.strict_section_order)
              );
              return (
                <button
                  onClick={() => {
                    if (!disabled) {
                      setSelected(section.id);
                    } else {
                      if (section.attempt?.is_completed) {
                        toast("Section is already completed");
                      } else if (
                        data.strict_section_order &&
                        section.id !== next_section
                      ) {
                        toast(
                          "Complete previous sections to unlock this section"
                        );
                      }
                    }
                  }}
                  key={section.id}
                  className={twMerge(
                    "w-full border rounded-md bg-white flex items-stretch flex-col",
                    section.attempt?.is_completed
                      ? "border-2 border-green-600 cursor-not-allowed"
                      : section.attempt
                      ? "border-2 border-yellow-600"
                      : "",
                    disabled ? "cursor-not-allowed" : "",
                    selected === section.id ? "bg-blue-600 bg-opacity-25" : ""
                  )}
                >
                  {(section.attempt?.is_completed || section.attempt) && (
                    <div
                      className={twMerge(
                        "py-1 w-full rounded-t-md text-white font-medium text-sm",
                        section.attempt?.is_completed
                          ? "bg-green-600 cursor-not-allowed"
                          : section.attempt
                          ? "bg-yellow-600"
                          : ""
                      )}
                    >
                      {section.attempt?.is_completed ? (
                        <div>
                          Section Completed - Ended by{" "}
                          {section.attempt.ending_method}
                        </div>
                      ) : data.test_attempt?.current_section_id ===
                        section.id ? (
                        <div className="font-medium">Current section</div>
                      ) : (
                        <div className="font-medium">Unfinished Section</div>
                      )}
                    </div>
                  )}
                  <div className="flex">
                    <div
                      className={twMerge(
                        "flex items-center flex-grow px-2 py-2 bg-slate-50",
                        selected === section.id
                          ? "bg-blue-600 bg-opacity-50"
                          : ""
                      )}
                    >
                      {section.attempt?.is_completed ? (
                        <CheckBadgeIcon className="w-5 h-5 text-green-600" />
                      ) : disabled ? (
                        <LockClosedIcon className="w-5 h-5" />
                      ) : (
                        <input type="radio" checked={selected === section.id} />
                      )}
                    </div>
                    <div className="flex flex-col items-start w-full px-5 py-2">
                      <div>
                        Section #{i + 1} -{" "}
                        <span className="font-medium">{section.title}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {[
                          {
                            label: "Questions",
                            value:
                              section.test_section_questions_aggregate.aggregate
                                .count,
                          },
                          {
                            label: data.isPractice
                              ? "*Practice Mode"
                              : section.is_timed
                              ? "Minutes"
                              : "*Untimed section",
                            value:
                              section.is_timed && !data.isPractice
                                ? section.duration
                                : "",
                          },
                        ].map((el, i) => {
                          return (
                            <>
                              {i > 0 && <div>|</div>}
                              <div className="flex items-center gap-1">
                                <span className="text-sm font-medium">
                                  {el.value}
                                </span>
                                <span className="text-sm">{el.label}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <td className="p-2">Section #{i + 1}</td>
          
            <td className="p-2">
              {el.is_timed ? `${el.duration} Minutes` : "*Untimed section"}{" "}
            </td> */}
                </button>
              );
            })}
            <button
              onClick={() => {
                if (selected) {
                  chooseSection({
                    test_section_id: selected,
                  });
                } else {
                  toast.error("Select a section first");
                }
              }}
              type="submit"
              className={twMerge(
                `w-full px-5 py-2 gap-2 text-sm flex items-center justify-center text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                submitting ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
              )}
              disabled={submitting}
            >
              {submitting ? (
                <ArrowPathIcon className="w-5 h-5 animate-spin" />
              ) : null}
              {submitting ? "Submitting" : "Submit"}
            </button>
            <div>
              <span>Untimed Section*</span>
              <span>
                * Untimed: These sections are without any specific time limit.
                You can answer these sections within the total assessment time
                limit. Total Time of Untimed Sections = Total time of test -
                Total time of Timed sections
              </span>
            </div>
          </div>
        </div>
      </main>
      {/* <footer className="fixed bottom-0 w-full px-16 py-2 text-xs text-center bg-white">
        Meritcurve @ AI SmartLabs LLP
      </footer> */}
    </div>
  );
}

export default ChooseSection;
