import { twMerge } from "tailwind-merge";

const Button = ({ children, className, type = "button", ...rest }) => {
  return (
    <button
      className={twMerge(
        `inline-flex items-center justify-center gap-2 px-4 py-2 focus:outline-none rounded disabled:opacity-75 text-sm`,
        className
      )}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
