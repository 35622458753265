import { useState } from "react";
import Modal from "@/components/ui/Modal";
import Button from "@/components/ui/Button";

function ConfirmationModal({
  open,
  onClose,
  onConfirm = async () => {},
  title = "",
  message = "",
}) {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => {
        if (!loading) {
          onClose();
        }
      }}
      title={title}
      className="max-w-sm"
    >
      <div className="flex flex-col justify-center gap-5 text-center">
        <p className="text-xl">{message}</p>{" "}
        <div className="flex justify-center gap-3">
          <Button
            onClick={async () => {
              setLoading(true);
              await onConfirm(true);
              setLoading(false);
              onClose();
            }}
            disabled={loading}
            className="font-medium text-white bg-indigo-600"
          >
            {loading ? "Processing..." : "I'm Sure"}
          </Button>
          <Button
            onClick={async () => {
              onClose(false);
            }}
            disabled={loading}
            className="font-medium text-white bg-red-600"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
