import AssessmentOverviewCarousal from "@/components/welcome/AssessmentOverviewCarousal";

function AssessmentOverview({
  data,
  setProceed,
  questionStats,
  sectionCount,
  testDuration,
}) {
  return (
    <div className="flex flex-col w-full min-h-full gap-2 text-white bg-slate-800">
      <header className="sticky top-0 px-16 py-5 text-lg bg-slate-800">
        <div className="flex items-center gap-2">
          {data?.university?.icon && (
            <img
              src={data?.university?.icon}
              alt="Icon"
              className="object-contain w-8 h-8"
            />
          )}

          {data?.university?.name ?? "Meritcurve"}
        </div>
      </header>
      <main className="grid items-center flex-grow grid-cols-1 gap-2 pb-12 text-xl lg:grid-cols-2">
        <div className="flex flex-col mt-8">
          <div className="flex flex-col px-16">
            <div className="text-2xl text-slate-500">Hi,</div>
            <div className="mt-5 text-2xl">Welcome to</div>
            <div className="mt-5 text-4xl">{data.title}</div>
            <hr className="max-w-xs my-12" />
            <div className="flex flex-col justify-between gap-5 sm:flex-row">
              {[
                {
                  key: "Question count:",
                  value: `${questionStats.count} Questions`,
                },
                {
                  key: "Total Marks:",
                  value: `${questionStats.maxMarks} Marks`,
                },
                {
                  key: "Section count:",
                  value: `${sectionCount} Sections`,
                },
                {
                  key: "Test Duration:",
                  value: `${testDuration} Minutes`,
                },
              ].map((el) => {
                return (
                  <div className="flex flex-col">
                    <div className="text-sm text-slate-300">{el.key}</div>
                    <div className="text-lg">{el.value}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full px-0 mt-5 lg:mt-12 lg:px-16">
            <button
              onClick={() => {
                setProceed(true);
              }}
              className="fixed bottom-0 z-50 w-full px-5 py-2 text-sm text-white bg-blue-800 rounded-md lg:relative lg:w-auto"
            >
              Proceed
            </button>
          </div>
        </div>
        {/* <div className="p-5 lg:p-0">
          <AssessmentOverviewCarousal />
        </div> */}
      </main>
      {/* <footer className="fixed bottom-0 w-full px-16 py-2 text-xs text-center bg-slate-800">
        Meritcurve @ AI SmartLabs LLP
      </footer> */}
    </div>
  );
}

export default AssessmentOverview;
