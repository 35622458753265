import { useCallback } from "react";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";

const Timer = forwardRef(({ children, time_elapsed = 0 }, ref) => {
  const calculateState = (start) => {
    const now = new Date();
    const total = Math.floor((now.getTime() - start.getTime()) / 1000);
    const hour = `00${Math.floor(total / 3600)}`.slice(-2);
    const min = `00${Math.floor(total / 60) % 60}`.slice(-2);
    const sec = `00${Math.floor(total % 60)}`.slice(-2);
    return { hour, min, sec, total, start };
  };
  const [state, setState] = useState(
    calculateState(new Date(new Date().getTime() - time_elapsed * 1000))
  );
  // if (!children) console.log("time_elapsed", time_elapsed, state.total);

  const updateTimer = useCallback(() => {
    setState(({ start }) => calculateState(start));
  }, []);
  useEffect(() => {
    const timer = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [updateTimer]);

  useImperativeHandle(ref, () => ({
    getTimerState() {
      return state;
    },
    resetTimer() {
      setState(
        calculateState(new Date(new Date().getTime() - time_elapsed * 1000))
      );
    },
  }));
  return children ? children(state) : null;
});

Timer.displayName = "Timer";

export default Timer;
