/* eslint-disable jsx-a11y/media-has-caption */
import { useAuth } from "@/components/AuthProvider";
import { ReactMediaRecorder } from "@/components/attempts/questions/mediaRecorder";
import { uploadToS3 } from "@/utilities/s3Helpers";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import { memo, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { BiRefresh, BiStopCircle } from "react-icons/bi";
import { FaPauseCircle } from "react-icons/fa";
import { RiPlayCircleLine, RiRecordCircleLine } from "react-icons/ri";
import { twMerge } from "tailwind-merge";
import VideoS3 from "./VideoS3";
import TimerVideo from "./TimerRef";
import { useQuestion } from "../TestAttemptController";

const VideoPreview = memo(({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={500} height={500} autoPlay />;
});
VideoPreview.displayName = "VideoPreview";

function VideoType({ save, saving }) {
  const { isLast, test_section_question } = useQuestion();

  const { watch, setValue } = useFormContext();
  const { token } = useAuth();
  const values = watch("answer");
  const [uploading, setUploading] = useState(false);

  return (
    <div className="flex flex-col w-full max-h-screen px-0 pt-16 pb-20 border-black">
      <div className="flex flex-col justify-between h-full gap-3">
        <div className="flex flex-col w-full gap-3 overflow-y-auto">
          <div className="px-2 py-6 scrollBar sm:px-0">
            <div>
              <div className="text-gray-700 bg-gray-100 md:px-5">
                <ReactMediaRecorder
                  video
                  audio
                  onStop={async (url, blob) => {
                    const path = `video_submissions/${new Date().toISOString()}.mp4`;
                    setUploading(true);
                    try {
                      const resData = await uploadToS3({
                        file: new File([blob], "video.mp4"),
                        path,
                        token: token.current,
                        type: "video/mp4",
                      });

                      setValue("answer", {
                        answer: resData.path,
                      });
                      save(false, true);
                      setUploading(false);
                    } catch (error) {
                      setUploading(false);
                      console.log(error);
                    }
                  }}
                  onPause={() => {
                    setValue("status", "paused");
                  }}
                  onResume={() => {
                    setValue("status", "recording");
                  }}
                  onStart={() => {
                    setValue("status", "recording");
                  }}
                >
                  {({
                    status,
                    startRecording,
                    stopRecording,
                    mediaBlobUrl,
                    previewStream,
                    pauseRecording,
                    resumeRecording,
                    timerRef,
                  }) => {
                    return (
                      <div className="flex flex-col w-full gap-5 p-3 rounded-md sm:p-8">
                        <div className="flex flex-col-reverse md:flex-row md:gap-3 md:justify-between md:items-center">
                          <div>Record your answer here</div>
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="flex items-center gap-5">
                            <TimerVideo ref={timerRef}>
                              {(duration) => {
                                return status === "recording" ||
                                  status === "paused" ? (
                                  <div className="flex gap-1 text-3xl font-semibold">
                                    <span>
                                      {`${Math.floor(duration / 60)}`.padStart(
                                        2,
                                        "0"
                                      )}
                                    </span>
                                    <span>:</span>
                                    <span>
                                      {`${Math.floor(duration % 60)}`.padStart(
                                        2,
                                        "0"
                                      )}
                                    </span>
                                  </div>
                                ) : null;
                              }}
                            </TimerVideo>
                            {status === "idle" ? (
                              values?.answer ? (
                                <div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      startRecording();
                                    }}
                                  >
                                    <BiRefresh className="w-12 h-12 text-red-600" />
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      startRecording();
                                    }}
                                  >
                                    <RiRecordCircleLine className="w-12 h-12 text-red-600" />
                                  </button>
                                </div>
                              )
                            ) : null}
                            {status === "recording" ? (
                              <div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    pauseRecording();
                                  }}
                                >
                                  <FaPauseCircle className="w-12 h-12 text-red-600" />
                                </button>
                              </div>
                            ) : null}
                            {status === "paused" ? (
                              <div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    resumeRecording();
                                  }}
                                >
                                  <RiPlayCircleLine className="w-12 h-12 text-red-600" />
                                </button>
                              </div>
                            ) : null}
                            {status === "stopped" ? (
                              <div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!uploading) startRecording();
                                  }}
                                  className="flex items-center gap-2"
                                >
                                  <BiRefresh
                                    className={`w-12 h-12 text-red-600 flex items-center gap-2 ${
                                      uploading ? "animate-spin" : ""
                                    }`}
                                  />
                                  {uploading ? "Uploading..." : ""}
                                </button>
                              </div>
                            ) : null}
                            {status === "recording" || status === "paused" ? (
                              <div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    stopRecording();
                                  }}
                                >
                                  <BiStopCircle className="w-12 h-12 text-red-600" />
                                </button>
                              </div>
                            ) : null}
                          </div>
                          {values?.answer && status === "idle" ? (
                            <div>
                              <VideoS3 path={values?.answer} />
                            </div>
                          ) : mediaBlobUrl && status !== "recording" ? (
                            <div>
                              <video controls controlsList="nodownload">
                                <source src={mediaBlobUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ) : status === "recording" || status === "paused" ? (
                            <div>
                              <VideoPreview stream={previewStream} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }}
                </ReactMediaRecorder>
              </div>
              <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
                <button
                  onClick={() => save(true, true)}
                  type="button"
                  className={twMerge(
                    `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                    saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
                  )}
                  disabled={saving}
                >
                  {saving ? (
                    <ArrowPathIcon className="w-5 h-5 animate-spin" />
                  ) : null}
                  {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
                </button>
                <Countdown
                  autoStart={true}
                  renderer={() => {
                    return (
                      <span className="text-xs">
                        {`Updated ${
                          test_section_question?.test_question_submissions?.[0]
                            ? formatDistanceToNowStrict(
                                new Date(
                                  test_section_question?.test_question_submissions?.[0].updated_at
                                ),
                                { addSuffix: true }
                              )
                            : "never"
                        }`}
                      </span>
                    );
                  }}
                  date={addDays(new Date(), 1)}
                  intervalDelay={5000}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoType;
