import React from "react";

function NotFound() {
  return (
    <div className="bg-indigo-600 flex flex-col justify-center items-center h-screen w-full">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="w-full flex justify-center">
          {/* <img src={duck} className="w-64 h-64 " alt="404 Not found" /> */}
        </div>
        <div className="flex flex-col justify-center my-16 gap-8">
          <div className="text-white text-5xl text-center font-semibold">
            Oops Page not found
          </div>
          <div className="text-white text-center">
            Please check the link you're trying to access
          </div>
        </div>
        {/* <div className="flex justify-center">
          <button
            className="w-64 text-white whitespace-no-wrap bg-black rounded-md py-2"
            onClick={() => navigate("/home")}
          >
            Take me home
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default NotFound;
