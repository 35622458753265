import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { useAssessment, useQuestion } from "../TestAttemptController";
import { produce } from "immer";

function FillupType({ save, saving }) {
  const { setValue, watch } = useFormContext();
  const { isLast, test_section_question } = useQuestion();
  const { disable_copy_paste } = useAssessment();
  const values = watch();
  return (
    <div className="py-5 text-gray-700 md:px-5">
      <div className="flex flex-col w-full gap-4 px-5 mt-5 sm:px-8">
        {Array(test_section_question.question.data.noOfBlanks)
          .fill()
          .map((_, i) => {
            return (
              <div
                key={`blanks${i}`}
                className="flex items-center w-full gap-3 lg:w-1/2 "
              >
                <div className="w-20">{`Blank ${i + 1}:`}</div>
                <div className="w-full">
                  <input
                    onChange={(e) => {
                      setValue(
                        "answer",
                        produce(values.answer, (draft) => {
                          if (!draft) {
                            draft = {};
                          }
                          if (!draft.answer) {
                            draft.answer = [];
                          }
                          draft.answer[i] = e.target.value;
                          return draft;
                        })
                      );
                    }}
                    onPaste={(e) => {
                      if (disable_copy_paste) {
                        e.preventDefault();
                        toast.error("Paste not allowed.");
                        return false;
                      }
                    }}
                    onCopy={(e) => {
                      if (disable_copy_paste) {
                        e.preventDefault();
                        toast.error("Copy not allowed.");
                        return false;
                      }
                    }}
                    onCut={(e) => {
                      if (disable_copy_paste) {
                        e.preventDefault();
                        toast.error("Cut not allowed.");
                        return false;
                      }
                    }}
                    className="w-full p-2 text-sm border border-gray-400 rounded-md bg-slate-50 form-input focus:shadow-none focus:border-blue-r1"
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
        <button
          onClick={() => save(true, true)}
          type="button"
          className={twMerge(
            `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
          )}
          disabled={saving}
        >
          {saving ? <ArrowPathIcon className="w-5 h-5 animate-spin" /> : null}
          {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
        </button>
        <Countdown
          autoStart={true}
          renderer={() => {
            return (
              <span className="text-xs">
                {`Updated ${
                  test_section_question?.test_question_submissions?.[0]
                    ? formatDistanceToNowStrict(
                        new Date(
                          test_section_question?.test_question_submissions?.[0].updated_at
                        ),
                        { addSuffix: true }
                      )
                    : "never"
                }`}
              </span>
            );
          }}
          date={addDays(new Date(), 1)}
          intervalDelay={5000}
        />
      </div>
    </div>
  );
}

export default FillupType;
