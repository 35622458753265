import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useQuery } from "@tanstack/react-query";
import { differenceInSeconds } from "date-fns";
import { useMemo, useEffect, useState } from "react";
import {
  browserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isMobile,
} from "react-device-detect";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { BroadcastChannel } from "broadcast-channel";

const SystemCompatibilityComponent = ({
  isLoading,
  isValid,
  browserError,
  deviceCheck,
  isSingleTab,
  isLocalStorageSupported,
  data,
  next,
}) => {
  const renderStatusIcon = (condition) =>
    condition ? (
      <BsFillCheckCircleFill className="text-green-600" />
    ) : (
      <RiCloseFill className="text-red-600" />
    );

  const renderLoadingSpinner = () => (
    <div className="w-6 h-6 spinner-grow"></div>
  );

  const renderMessage = () => {
    if (isLoading) {
      return <div>Checking System Compatibility, Please wait</div>;
    }
    if (
      isValid &&
      !browserError &&
      deviceCheck &&
      isSingleTab &&
      isLocalStorageSupported
    ) {
      return <div>All Set, Please Proceed</div>;
    }
    return <div>Oops, looks like the system is not compatible</div>;
  };

  return (
    <div className="flex flex-col items-start w-full gap-5 pb-8 pl-8 pr-16">
      {renderMessage()}

      <div className="flex flex-col w-full px-5 py-2 border border-gray-500 rounded-md bg-slate-100">
        <div className="flex items-center w-full gap-5">
          <div>
            {isLoading
              ? renderLoadingSpinner()
              : renderStatusIcon(!browserError && isSingleTab)}
          </div>
          <div className="font-medium">1. Browser Compatibility</div>
        </div>
        <div className="w-full">{isLoading ? "" : browserError}</div>
        <div className="w-full">
          {isLoading
            ? ""
            : !isSingleTab && (
                <div className="mt-2 mb-2 font-mono text-sm">
                  It appears that there are multiple assessment browser tabs
                  open in the system. Please note that only one assessment
                  browser tab can be active at a time. Kindly close all other
                  assessment tabs and refresh this page to proceed. If this
                  issue persists, kindly close the tab, log out, and then sign
                  back in before attempting to take the assessment again.
                </div>
              )}
          {!isLoading && !isLocalStorageSupported && (
            <div className="mt-2 mb-2 font-mono text-sm">
              It appears that your browser doesn't support localStorage.
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full px-5 py-2 border border-gray-500 rounded-md bg-slate-100">
        <div className="flex items-center w-full gap-5">
          <div>
            {isLoading ? renderLoadingSpinner() : renderStatusIcon(isValid)}
          </div>
          <div className="font-medium">2. System Compatibility</div>
        </div>
        <div className="w-full">
          {isLoading || isValid ? "" : "Your system clock is incorrect."}
        </div>
      </div>

      <div className="flex flex-col w-full px-5 py-2 border border-gray-500 rounded-md bg-slate-100">
        <div className="flex items-center w-full gap-5">
          <div>
            {isLoading ? renderLoadingSpinner() : renderStatusIcon(deviceCheck)}
          </div>
          <div className="font-medium">3. Device Compatibility</div>
        </div>
        <div className="w-full">
          {isLoading || deviceCheck ? "" : "Mobile devices are not allowed."}
        </div>
      </div>

      <div className="flex flex-col w-full px-5 py-2 border border-gray-500 rounded-md bg-slate-100">
        <div className="flex items-center w-full gap-5">
          <div>
            {isLoading ? renderLoadingSpinner() : renderStatusIcon(data)}
          </div>
          <div className="font-medium">4. Internet Connectivity</div>
        </div>
        <div className="w-full">
          {isLoading || data
            ? ""
            : "Couldn't contact server. Please check your internet connectivity"}
        </div>
      </div>

      {isValid && !browserError && isSingleTab && isLocalStorageSupported && (
        <button
          onClick={next}
          className="w-full px-5 py-2 text-sm text-white bg-blue-800 rounded-md lg:relative lg:w-auto"
        >
          Proceed
        </button>
      )}
    </div>
  );
};

function SystemCompatibility({ next, data: assessment }) {
  console.log("assessment", assessment);
  const { isLoading, data } = useQuery(
    ["compatibility"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/servertime`, {})
        .then((res) => res.json())
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw new Error(error);
        }),
    {}
  );
  const isValid = useMemo(() => {
    if (data) {
      return (
        Math.abs(differenceInSeconds(new Date(data.now), new Date())) < 180
      );
    }
    return false;
  }, [data]);
  const browserError = useMemo(() => {
    if (isChrome && browserVersion <= 54)
      return "Chrome Version should be greater than 54";
    if (isEdge && browserVersion <= 110)
      return "Edge Version should be greater than 11";
    if (isFirefox && browserVersion <= 49)
      return "Firefox Version should be greater than 49";
    return isChrome || isEdge || isFirefox
      ? null
      : "Only Chrome, Firefox and Edge are supported";
  }, []);

  const deviceCheck = useMemo(() => {
    if (assessment?.disable_mobile) {
      return !isMobile;
    }
    return true;
  }, [assessment]);

  const useMultipleTabsDetection = () => {
    const [isSingleTab, setIsSingleTab] = useState(true);
    const channelName = "meritcurve-assessment-app-channel";
    const tabId = Math.random().toString(36).substring(2);

    useEffect(() => {
      const channel = new BroadcastChannel(channelName);

      const handleMessage = (message) => {
        if (message === "check") {
          channel.postMessage(`tab-${tabId}`);
        } else if (message.startsWith("tab-") && message !== `tab-${tabId}`) {
          setIsSingleTab(false);
        }
      };

      channel.onmessage = handleMessage;

      // Check if other tabs are open
      channel.postMessage("check");

      return () => {
        channel.close();
      };
    }, [channelName, tabId]);

    return isSingleTab;
  };

  const checkLocalStorageSupported = () => {
    try {
      const testKey = "__MeritCurvelocalStorageTest__";
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      console.log("isLocalStorageSupportederror: ", error);
      return false;
    }
  };

  const isSingleTab = useMultipleTabsDetection();
  const isLocalStorageSupported = checkLocalStorageSupported();

  console.log("isSingleTab: ", isSingleTab);

  return (
    <SystemCompatibilityComponent
      isLoading={isLoading}
      isValid={isValid}
      browserError={browserError}
      deviceCheck={deviceCheck}
      isSingleTab={isSingleTab}
      isLocalStorageSupported={isLocalStorageSupported}
      data={data}
      next={next}
    />
  );
}

export default SystemCompatibility;
