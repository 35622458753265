import FileViewerComponent from "@/components/FileViewerComponent";
import Modal from "@/components/ui/Modal";

const FilePreviewModal = ({ open, onClose, type, url }) => {
  console.log("type", type);
  console.log("url", url);
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="File Preview"
      fullScreen={true}
    >
      <div className="flex justify-center flex-grow w-full p-5 overflow-y-auto h-128">
        <FileViewerComponent type={type} url={url} />
      </div>
    </Modal>
  );
};

export default FilePreviewModal;
