import { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

const CustomStyledSelect = ({
  className = "",
  placeholder,
  options,

  onCreateOption,
  isMulti = false,
  hideDropdown = false,
  hideOptions = false,
  isSearchable = false,
  isLoading = false,
  isDisabled = false,
  isCreatable = false,
  isSearchableOnly = false,
  isClearable = true,
  clearIndicatorVisible = false,
  padded = false,
  icon: Icon,
  theme = "light",
  onChangeCallback = () => {},
  name,
  control,
  onlyValue = true,
}) => {
  const [inputValue, setInputValue] = useState("");
  const components = {
    // IndicatorSeparator: () => null,
    SingleValue: (props) => <span>{props.children}</span>,
    [!clearIndicatorVisible ? "ClearIndicator" : null]: () => null,
    [hideDropdown || isCreatable || isSearchableOnly
      ? "DropdownIndicator"
      : null]: () => null,
    [hideOptions ? "Option" : null]: () => null,
  };
  const themeMap = {
    dark: {
      control: (provided) => ({
        ...provided,
        // backgroundColor: state.isFocused ? "#fff" : "#f7fafc",
        // height: "35px",
        [Icon ? "paddingLeft" : null]: "32px",
        border: "none",
        backgroundColor: isDisabled ? "#292929" : "#333",
        borderWidth: 0,
        paddingTop: "2px",
        paddingBottom: "2px",
        whiteSpace: "nowrap",
        boxShadow: "none",
        borderRadius: "0.5rem",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#1F4BEC" : "#fff",
        ":active": {
          backgroundColor: "#DEEBFF",
        },
        ":hover": {
          backgroundColor: state.isSelected ? "#1F4BEC" : "#DEEBFF",
        },
      }),

      placeholder: (provided) => ({
        ...provided,
        // color: "#a0aec0",
        color: "#D2D2D2",
        margin: 0,
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        flexWrap: isMulti ? "wrap" : "nowrap",
        borderRadius: "0.5rem",
        backgroundColor: isDisabled ? "#292929" : "#333",
        color: "white",
        paddingLeft: "1.25rem",
      }),
      menu: (provided) => ({
        ...provided,
        [isCreatable && !inputValue ? "display" : null]: "none",
      }),
    },

    light: {
      control: (provided) => ({
        ...provided,
        paddingTop: "1px",
        paddingBottom: "1px",
        // backgroundColor: state.isFocused ? "#fff" : "#f7fafc",
        whiteSpace: "nowrap",
        // height: "35px",

        [padded ? "paddingTop" : null]: "7px",
        [padded ? "paddingBottom" : null]: "7px",
        [padded ? "paddingRight" : null]: "1rem",
        [padded ? "paddingLeft" : null]: "1.5rem",
        [Icon ? "paddingLeft" : null]: "32px",
        border: "none",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#1F4BEC" : "#fff",
        ":active": {
          backgroundColor: "#DEEBFF",
        },
        ":hover": {
          backgroundColor: state.isSelected ? "#1F4BEC" : "#DEEBFF",
        },
      }),

      placeholder: (provided) => ({
        ...provided,
        // color: "#a0aec0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        flexWrap: isMulti ? "wrap" : "nowrap",
      }),
      menu: (provided) => ({
        ...provided,
        [isCreatable && !inputValue ? "display" : null]: "none",
      }),
    },
  };

  const onChangeHandler = (onChange) => (option) => {
    if (option) {
      const value = isMulti
        ? option
          ? onlyValue
            ? option.map((el) => el.value)
            : option
          : []
        : onlyValue
        ? option.value
        : option;
      onChange(value);
      onChangeCallback(value);
    } else onChange(option);
  };

  return (
    <div className={`w-full ${Icon ? "relative" : ""}`}>
      {isCreatable ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, onBlur, name } }) => {
            return (
              <CreatableSelect
                className={twMerge(
                  `flex-auto lg:inline-block text-black focus:outline-none w-full text-sm font-normal border rounded-md bg-slate-50 focus:shadow-none`,
                  className
                )}
                onBlur={onBlur}
                styles={themeMap[theme]}
                closeMenuOnSelect={!isMulti}
                inputValue={inputValue}
                onInputChange={(value) => {
                  setInputValue(value);
                }}
                components={components}
                name={name}
                value={
                  isMulti
                    ? options && value
                      ? options.filter(
                          (option) =>
                            value.findIndex(
                              (el) =>
                                (onlyValue ? el : el.value) === option.value
                            ) >= 0
                        )
                      : []
                    : options && value
                    ? options.find(
                        (option) =>
                          option.value === (onlyValue ? value : value.value)
                      )
                    : null
                }
                onChange={onChangeHandler(onChange)}
                formatCreateLabel={(value) => {
                  return <div>{`Add "${value}"`}</div>;
                }}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isSearchable={true}
                isClearable={isClearable}
                createOptionPosition="last"
                onCreateOption={(value) => {
                  if (onCreateOption)
                    onCreateOption(value).then(() => {
                      return onChange(
                        isMulti
                          ? options
                            ? value && value.length > 0
                              ? [...value, value]
                              : [value]
                            : []
                          : options
                          ? value
                          : ""
                      );
                    });
                }}
              />
            );
          }}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, name } }) => {
            return (
              <Select
                className={twMerge(
                  `flex-auto lg:inline-block text-black focus:outline-none w-full text-sm font-normal border rounded-md bg-slate-50 focus:shadow-none`,
                  className
                )}
                styles={themeMap[theme]}
                closeMenuOnSelect={!isMulti}
                components={components}
                name={name}
                value={
                  isMulti
                    ? options && value
                      ? options.filter(
                          (option) =>
                            value.findIndex(
                              (el) =>
                                (onlyValue ? el : el.value) === option.value
                            ) >= 0
                        )
                      : []
                    : options && value
                    ? options.find(
                        (option) =>
                          option.value === (onlyValue ? value : value.value)
                      )
                    : ""
                }
                onChange={onChangeHandler(onChange)}
                formatCreateLabel={(value) => {
                  return <div>{`Add "${value}"`}</div>;
                }}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
              />
            );
          }}
        />
      )}
      {Icon ? (
        <div className="absolute inset-y-0 left-0 flex items-center">
          <Icon className="w-6 h-6 mx-3 text-gray-500" />
        </div>
      ) : null}
    </div>
  );
};

export default CustomStyledSelect;
