import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { produce } from "immer";
import { Base64 } from "js-base64";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useAuth } from "../AuthProvider";
import ErrorBoundary from "../ErrorBoundary";

import AudioType from "@/components/attempts/questions/audioType";
import CodingType from "@/components/attempts/questions/codingType";
import EssayType from "@/components/attempts/questions/essayType";
import FillupType from "@/components/attempts/questions/fillupType";
import MultipleMCQType from "@/components/attempts/questions/multipleMCQType";
import SingleLineType from "@/components/attempts/questions/singleLineType";
import SingleMCQType from "@/components/attempts/questions/singleMCQType";
import UploadType from "@/components/attempts/questions/uploadType";
import VideoType from "@/components/attempts/questions/videoType";
import { useParams } from "react-router-dom";
// const EssayType = lazy(() =>
//   import("@/components/attempts/questions/essayType")
// );
// const CodingType = lazy(() =>
//   import("@/components/attempts/questions/codingType")
// );
// const SingleMCQType = lazy(() =>
//   import("@/components/attempts/questions/singleMCQType")
// );
// const MultipleMCQType = lazy(() =>
//   import("@/components/attempts/questions/multipleMCQType")
// );
// const FillupType = lazy(() =>
//   import("@/components/attempts/questions/fillupType")
// );
// const AudioType = lazy(() =>
//   import("@/components/attempts/questions/audioType")
// );
// const VideoType = lazy(() =>
//   import("@/components/attempts/questions/videoType")
// );
// const UploadType = lazy(() =>
//   import("@/components/attempts/questions/uploadType")
// );
// const SingleLineType = lazy(() =>
//   import("@/components/attempts/questions/singleLineType")
// );

const QuestionTypeSwitch = forwardRef(({ ...props }, ref) => {
  const [saving, setSaving] = useState(false);
  const { type, data, test_section_question, next } = props;
  const duration = useMemo(() => {
    if (type === "audio" || type === "video") {
      return 60;
    }
    return 10;
  }, [type]);
  const start = useMemo(
    () =>
      new Date(
        new Date().getTime() -
          (test_section_question?.test_question_submissions?.[0]
            ? test_section_question.test_question_submissions[0].time_taken
            : 0) *
            1000
      ),
    []
  );
  const timer = useRef();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const { attempt_id } = useParams();
  const { mutate: saveAnswer } = useMutation(
    ({ showToast = true, ...variables }) => {
      const promise = axios
        .post(
          `${FUNCTION_BASE_URL}/saveAnswer?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data);
      if (showToast) {
        toast.promise(promise, {
          error: "Failed",
          loading: "Syncing",
          success: "Saved",
        });
      }
      return promise;
    },
    {
      onSuccess: async (d) => {
        console.log("d", d);
        if (d?.error) {
          toast.error(d?.error?.message || "Something went wrong");
        } else {
          const queryData = queryClient.getQueryData(["assessment", "attempt"]);
          if (!queryData || !queryData.tests_by_pk) {
            queryClient.refetchQueries(["assessment", "attempt"]);
            return;
          }
          queryClient.setQueryData(
            [
              "assessment",
              "attempt",
              data.test_attempts_by_pk.current_section_id,
            ],
            (currentSectionQuestionData) => {
              if (!currentSectionQuestionData) {
                return currentSectionQuestionData;
              }
              return produce(currentSectionQuestionData, (draft) => {
                const question = draft.test_section_questions.find(
                  (el) => el.id === d.test_section_question_id
                );
                if (question) {
                  if (question.test_question_submissions[0]) {
                    question.test_question_submissions[0].id = d.id;
                    question.test_question_submissions[0].is_marked =
                      d.is_marked ??
                      question?.test_question_submissions?.[0]?.is_marked;
                    question.test_question_submissions[0].updated_at =
                      d.updated_at ?? new Date().toISOString();
                    question.test_question_submissions[0].time_taken =
                      d.time_taken ??
                      question?.test_question_submissions?.[0]?.time_taken;
                    question.test_question_submissions[0].compiler_attempts_left =
                      d.compiler_attempts_left ??
                      question?.test_question_submissions?.[0]
                        ?.compiler_attempts_left;
                    question.test_question_submissions[0].is_corrected =
                      d.is_corrected ??
                      question?.test_question_submissions?.[0]?.is_corrected ??
                      false;
                  } else {
                    question.test_question_submissions[0] = {
                      id: d.id,
                      is_marked: d.is_marked,
                      answer: d.answer,
                      updated_at: d.updated_at ?? new Date().toISOString(),
                      time_taken: d.time_taken,
                      compiler_attempts_left: d.compiler_attempts_left,
                      is_corrected: d.is_corrected ?? false,
                    };
                  }
                }
                return draft;
              });
            }
          );
        }
      },
      onError: (error) => {
        console.log("saveAnswer error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },

      onMutate: async (variables, ...a) => {
        console.log("variables", a);
        if (variables) {
          try {
            await queryClient.cancelQueries([
              "assessment",
              "attempt",
              data.test_attempts_by_pk.current_section_id,
            ]);

            queryClient.setQueryData(
              [
                "assessment",
                "attempt",
                data.test_attempts_by_pk.current_section_id,
              ],
              (currentSectionQuestion) => {
                if (!currentSectionQuestion) {
                  return currentSectionQuestion;
                }
                return produce(currentSectionQuestion, (draft) => {
                  if (!draft) {
                    return draft;
                  }

                  const question = draft.test_section_questions.find(
                    (el) => el.id === variables.test_section_question_id
                  );
                  if (question) {
                    if (question.test_question_submissions[0]) {
                      question.test_question_submissions[0].answer =
                        variables.answer ??
                        question?.test_question_submissions?.[0]?.answer;
                    } else {
                      question.test_question_submissions[0] = {
                        is_marked:
                          variables.is_marked ??
                          question?.test_question_submissions?.[0]?.is_marked,
                        answer:
                          variables.answer ??
                          question?.test_question_submissions?.[0]?.answer,
                        updated_at:
                          variables.updated_at ?? new Date().toISOString(),
                        time_taken:
                          variables.time_taken ??
                          question?.test_question_submissions?.[0]?.time_taken,
                        compiler_attempts_left:
                          variables.compiler_attempts_left ??
                          question?.test_question_submissions?.[0]
                            ?.compiler_attempts_left,
                        is_corrected:
                          variables.is_corrected ??
                          question?.test_question_submissions?.[0]
                            ?.is_corrected ??
                          false,
                      };
                    }
                  }
                  return draft;
                });
              }
            );
          } catch (e) {
            console.error("Save Answer mutate", e);
          }
        }
      },
    }
  );

  const types = {
    single_mcq: SingleMCQType,
    multiple_mcq: MultipleMCQType,
    single_programming_question: CodingType,
    essay: EssayType,
    fill_up: FillupType,
    audio: AudioType,
    video: VideoType,
    upload: UploadType,
    single_line: SingleLineType,
  };

  const methods = useForm();
  useEffect(() => {
    if (
      data?.tests_by_pk?.disable_copy_paste &&
      (type === "fill_up" ||
        type === "essay" ||
        type === "single_line" ||
        type === "single_programming_question")
    ) {
      document.addEventListener("drop", (ev) => {
        ev.preventDefault();
        // toast.error("Dragging is disabled");
      });
    }
    return () => {
      document.removeEventListener("drop", function (event) {
        console.log("Key down event triggered:", event.key);
      });
    };
  }, [type, data]);
  const {
    getValues,
    setValue,
    formState: { isDirty },
  } = methods;
  const saveHandler = useCallback(
    async (p = { showToast: false, validateFn: null }) => {
      const { showToast = false, validateFn = null } = p;
      setSaving(true);

      const now = new Date();
      const total = Math.floor((now.getTime() - start.getTime()) / 1000);

      let answer = getValues("answer");

      if (validateFn && typeof validateFn === 'function') {
        const isValid = validateFn(answer);
        if (!isValid) {
          setSaving(false);
          return { success: false };
        }
      }

      if (type === "single_programming_question") {
        answer = {
          answer: {
            ...answer,
            code: Base64.encode(answer?.code || ""),
          },
        };
      }
      try {
        const resolvedPromiseData = await new Promise((resolve, reject) => {
          saveAnswer(
            {
              answer,
              test_attempt_id: data.test_attempts_by_pk.id,
              test_section_id: data.test_attempts_by_pk.current_section_id,
              test_section_question_id: test_section_question.id,
              time_taken: total,
              showToast,
            },
            { onSettled: resolve, onError: reject }
          );
        });
        setSaving(false);
        return { success: true, data: resolvedPromiseData };
      } catch (error) {
        setSaving(false);
        return { success: false, error };
      }
    },
    [data, getValues, saveAnswer, test_section_question, start, type]
  );
  useEffect(() => {
    const func = () => {
      saveHandler();
      timer.current = setTimeout(func, duration * 1000);
    };
    timer.current = setTimeout(func, duration * 1000);
    return () => {
      clearTimeout(timer.current);
      // saveHandler();
    };
  }, []);

  useEffect(() => {
    const answer =
      type === "single_programming_question"
        ? test_section_question?.test_question_submissions[0]?.answer?.answer
          ? {
              ...test_section_question.test_question_submissions[0].answer
                .answer,
              code: Base64.decode(
                test_section_question.test_question_submissions[0].answer.answer
                  .code || ""
              ),
            }
          : test_section_question.question.programming_snippets.length > 0
          ? {
              code: test_section_question.config.hide_starter_code
                ? ""
                : test_section_question.question.programming_snippets[0]
                    ?.snippet ??
                  test_section_question.question.programming_snippets[0]
                    ?.language.snippet,
              language: {
                label:
                  test_section_question.question.programming_snippets[0]
                    .language?.name,
                value:
                  test_section_question.question.programming_snippets[0]
                    .language?.id,
                mode: test_section_question.question.programming_snippets[0]
                  .language?.mode,
              },
            }
          : {
              code: test_section_question.config.hide_starter_code
                ? ""
                : data.languages[0]?.snippet,
              language: {
                label: data.languages[0]?.name,
                value: data.languages[0]?.id,
                mode: data.languages[0]?.mode,
              },
            }
        : {
            answer:
              test_section_question &&
              test_section_question.test_question_submissions[0] &&
              typeof test_section_question.test_question_submissions[0].answer
                ?.answer !== "undefined"
                ? test_section_question.test_question_submissions[0].answer
                    .answer
                : type === "upload" || type === "fill_up"
                ? []
                : type === "multiple_mcq"
                ? {}
                : "",
          };
    setValue(
      "answer",
      produce(answer, (draft) => draft)
    );
  }, [test_section_question.id]);

  useImperativeHandle(ref, () => ({
    save: saveHandler,
    isDirty: isDirty,
  }));

  const QuestionComponent = types[type];

  return (
    <FormProvider {...methods}>
      {/* <Timer
        ref={timerRef}
        time_elapsed={
          test_section_question?.test_question_submissions?.[0]
            ? test_section_question.test_question_submissions[0].time_taken
            : 0
        }
      /> */}
      <ErrorBoundary>
        {QuestionComponent ? (
          <QuestionComponent
            save={(goToNext = true, showToast = false, validateFn = null) => {
              saveHandler({ showToast, validateFn }).then((result) => {
                if (result.success && goToNext) {
                  next();
                }
              });
              clearTimeout(timer.current);

              const func = () => {
                saveHandler();
                timer.current = setTimeout(func, duration * 1000);
              };
              timer.current = setTimeout(func, duration * 1000);
            }}
            saving={saving}
            test={data.tests_by_pk}
            languages={data?.languages ?? []}
          />
        ) : null}
      </ErrorBoundary>
    </FormProvider>
  );
});
QuestionTypeSwitch.displayName = "QuestionTypeSwitch";
export default QuestionTypeSwitch;