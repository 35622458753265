import React from "react";
import MarkdownRender from "@/components/markdown/MarkdownRender";
import { twMerge } from "tailwind-merge";

function AssessmentInstructions({ data, next }) {
  return (
    <div className="flex flex-col w-full py-16 pr-5">
      <div className="flex justify-center">
        <div className="text-xl font-semibold">Assessment Instructions</div>
      </div>
      <MarkdownRender className="w-full px-5 py-5 mt-5 mb-5 border rounded-md shadow markdown">
        {data.instruction}
      </MarkdownRender>
      <div className="flex justify-end">
        <button
          onClick={next}
          className={twMerge(
            `w-full flex items-center justify-center gap-3 px-5 py-2 text-sm text-white bg-blue-800 rounded-md lg:relative lg:w-auto`
          )}
        >
          Proceed
        </button>
      </div>
    </div>
  );
}

export default AssessmentInstructions;
