import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import LogRocket from "logrocket";
import { useAuth } from "@/components/AuthProvider";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Suspense } from "react";
import ChooseSection from "./pages/assessment/attempt/chooseSection";
import ErrorBoundary from "./components/ErrorBoundary";

// const NotFound = lazy(() => import("@/pages/404"));
import NotFound from "./pages/404";
import TestAttempt from "./pages/assessment/attempt";
import AssessmentComplete from "./pages/assessment/attempt/complete";
// const TestAttempt = lazy(() => import("@/pages/assessment/attempt"));

// const AssessmentComplete = lazy(() =>
//   import("@/pages/assessment/attempt/complete")
// );

// const AssessmentInstructions = lazy(() =>
//   import("@/pages/assessment/instructions")
// );
import Welcome from "./pages/welcome";
// const Welcome = lazy(() => import("@/pages/welcome"));
export default function AllRoutes() {
  const auth = useAuth();

  useEffect(() => {
    if (auth.currentUser) {
      LogRocket.identify(auth.currentUser?.email, {
        name: auth.currentUser?.displayName,
        email: auth.currentUser?.email,
      });
      Sentry.setUser({
        email: auth.currentUser?.email,
        id: auth.currentUser.id,
      });
    }
  }, [auth]);

  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full min-h-[500px] text-2xl font-semibold">
          <div className="w-6 h-6 spinner-grow" />
          <div className="ml-3">Loading</div>
        </div>
      }
    >
      <Routes>
        <Route
          path="/welcome/:test_id"
          element={
            <ErrorBoundary>
              <Welcome auth={true} />
            </ErrorBoundary>
          }
        />
        {/* <Route path="/instructions" element={<AssessmentInstructions />} /> */}
        <Route
          path="/assessment/:test_id"
          element={
            <ErrorBoundary>
              <Welcome />
            </ErrorBoundary>
          }
        />
        <Route
          path="/attempt/:attempt_id/complete"
          element={
            <ErrorBoundary>
              <AssessmentComplete />
            </ErrorBoundary>
          }
        />
        <Route
          path="/attempt/:attempt_id"
          element={
            <ErrorBoundary>
              <TestAttempt />
            </ErrorBoundary>
          }
        />
        <Route
          path="/chooseSection/:test_id"
          element={
            <ErrorBoundary>
              <ChooseSection />
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary>
              <NotFound />
            </ErrorBoundary>
          }
        />
      </Routes>
    </Suspense>
  );
}
