import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { useAssessment, useQuestion } from "../TestAttemptController";
import { produce } from "immer";

function EssayType({ save, saving }) {
  const { isLast, test_section_question } = useQuestion();
  const { disable_copy_paste } = useAssessment();
  const { register, watch, setValue } = useFormContext();
  const values = watch();
  return (
    <div className="flex flex-col w-full gap-3 overflow-y-auto">
      <div className="px-2 py-6 scrollBar sm:px-0">
        <div>
          <div className="text-gray-700 md:px-5">
            <div className="w-full p-3 bg-white rounded-md sm:p-8 ">
              <div className="flex flex-col-reverse md:flex-row md:gap-3 md:justify-between md:items-center">
                <div>Type your answer here</div>
              </div>
            </div>
            <textarea
              className="w-full p-2 overflow-y-auto text-sm border-2 rounded-md"
              spellCheck={false}
              rows={10}
              name="answer.answer"
              value={values?.answer?.answer ?? ""}
              onChange={(e) => {
                setValue(
                  "answer",
                  produce(values.answer, (draft) => {
                    if (!draft) {
                      draft = {};
                    }
                    draft.answer = e.target.value;
                    return draft;
                  })
                );
              }}
              onPaste={(e) => {
                if (disable_copy_paste) {
                  e.preventDefault();
                  toast.error("Paste not allowed.");
                  return false;
                }
              }}
              onCopy={(e) => {
                if (disable_copy_paste) {
                  e.preventDefault();
                  toast.error("Copy not allowed.");
                  return false;
                }
              }}
              onCut={(e) => {
                if (disable_copy_paste) {
                  e.preventDefault();
                  toast.error("Cut not allowed.");
                  return false;
                }
              }}
            />
            <div className="flex justify-between text-xs">
              <div>{`Words: ${
                typeof values?.answer?.answer === "string" &&
                values?.answer?.answer.trim()
                  ? values?.answer?.answer
                      .trim()
                      .replace(/[\s]+/g, " ")
                      .split(" ").length
                  : 0
              } | Characters: ${
                typeof values?.answer?.answer === "string"
                  ? values?.answer?.answer.trim().replace(/[\s]+/g, " ").length
                  : 0
              }`}</div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
            <button
              onClick={() => save(true, true)}
              type="button"
              className={twMerge(
                `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
              )}
              disabled={saving}
            >
              {saving ? (
                <ArrowPathIcon className="w-5 h-5 animate-spin" />
              ) : null}
              {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
            </button>
            <Countdown
              autoStart={true}
              renderer={() => {
                return (
                  <span className="text-xs">
                    {`Updated ${
                      test_section_question?.test_question_submissions?.[0]
                        ? formatDistanceToNowStrict(
                            new Date(
                              test_section_question?.test_question_submissions?.[0].updated_at
                            ),
                            { addSuffix: true }
                          )
                        : "never"
                    }`}
                  </span>
                );
              }}
              date={addDays(new Date(), 1)}
              intervalDelay={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EssayType;
