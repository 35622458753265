import ReactMarkdown from "react-markdown";
import "katex/dist/katex.min.css";
import RemarkMathPlugin from "remark-math";
import RemarkGfmPlugin from "remark-gfm";
import RehypeKatexPlugin from "rehype-katex";
import MarkdownCodeHighlighter from "./MarkdownCodeHighlighter";
// import Image from "next/image";

function MarkdownRender(props) {
  const newProps = {
    ...props,
    remarkPlugins: [RemarkMathPlugin, RemarkGfmPlugin],
    rehypePlugins: [RehypeKatexPlugin],
    components: {
      a: ({ href, children }) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),

      code: MarkdownCodeHighlighter,
      // img: Image,
    },
  };
  return <ReactMarkdown {...newProps} />;
}

export default MarkdownRender;
