import axios from "axios";
import { FUNCTION_BASE_URL } from "./constants";

const uploadToS3 = async ({ file, path, token, metadata = {}, type }) => {
  const { data: resData } = await axios.post(
    `${FUNCTION_BASE_URL}/getS3UpdateURL`,
    {
      path,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 30000,
    }
  );
  await axios.put(resData.url, file, {
    headers: {
      "Content-Type": type ?? file.type,
      ...Object.keys(metadata).reduce((prev, cur) => {
        return {
          ...prev,
          [`x-amz-meta-${cur}`]: metadata[cur],
        };
      }, {}),
    },
  });
  return resData;
};
const getFromS3 = async ({ path, token }) => {
  const { data: resData } = await axios.post(
    `${FUNCTION_BASE_URL}/getS3ReadURL`,
    {
      path,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 30000,
    }
  );

  return resData;
};

export { uploadToS3, getFromS3 };
