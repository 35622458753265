import Timer from "@/components/Timer";
import { addSeconds, max } from "date-fns";
import addMinutes from "date-fns/addMinutes";
import { useMemo } from "react";
import Countdown from "react-countdown";

function AssessmentTimer({
  data,
  section_wise_time_spent,
  testTimerEnd,
  endTestHandler,
  currentSection,
  endSectionHandler,
}) {
  const sectionTimerEnd = useMemo(() => {
    const bonusTime = currentSection.test_attempt_sections[0].bonus_time ?? 0;
    const endingTime = data.tests_by_pk.pause_when_inactive
      ? addSeconds(
          new Date(),
          currentSection.duration * 60 -
            section_wise_time_spent[currentSection.id]
        )
      : addMinutes(
          new Date(currentSection.test_attempt_sections[0].created_at),
          currentSection.duration
        );
    return max([
      addMinutes(endingTime, bonusTime),
      ...(bonusTime > 0
        ? [
            addMinutes(
              new Date(
                currentSection.test_attempt_sections[0].bonus_time_given_at
              ),
              bonusTime
            ),
          ]
        : []),
    ]);
  }, [data, currentSection, section_wise_time_spent]);
  return data.tests_by_pk.isPractice ? (
    <div className="flex items-center gap-2">
      <div className="hidden md:block">Test time elapsed</div>

      <Timer
        time_elapsed={Object.values(section_wise_time_spent).reduce(
          (prev, cur) => prev + cur,
          0
        )}
      >
        {({ hour, min, sec }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2 text-lg">
                <div>{hour}</div>
                <div>:</div>
                <div>{min}</div>
                <div>:</div>
                <div>{sec}</div>
              </div>
            </div>
          );
        }}
      </Timer>
    </div>
  ) : (
    testTimerEnd && (
      <div className="flex flex-col text-xs">
        <Countdown
          autoStart={true}
          daysInHours={true}
          // onTick={({ total }) => {
          //   console.log("total", total);
          // }}
          renderer={({ completed, formatted }) => {
            const { hours, minutes, seconds } = data.test_attempts_by_pk
              .is_completed
              ? {
                  days: "00",
                  hours: "00",
                  minutes: "00",
                  seconds: "00",
                }
              : formatted;

            return (
              <div className="flex items-center justify-end gap-3 text-sm">
                <div className="hidden md:block">Test Time remaining</div>
                <div className="flex justify-end">
                  <div className="flex gap-1">
                    <div>{completed ? "00" : hours}</div>
                    <div>:</div>
                    <div>{completed ? "00" : minutes}</div>
                    <div>:</div>
                    <div>{completed ? "00" : seconds}</div>
                  </div>
                </div>
              </div>
            );
          }}
          date={testTimerEnd}
          onMount={({ completed }) => {
            if (completed) {
              endTestHandler("timer");
            }
          }}
          onComplete={() => {
            endTestHandler("timer");
          }}
        />
        {currentSection.is_timed && (
          <Countdown
            autoStart={true}
            daysInHours={true}
            // onTick={({ total }) => {
            //   console.log("total", total);
            // }}
            renderer={({ completed, formatted }) => {
              const { hours, minutes, seconds } = data.test_attempts_by_pk
                .is_completed
                ? {
                    days: "00",
                    hours: "00",
                    minutes: "00",
                    seconds: "00",
                  }
                : formatted;

              return (
                <div className="flex items-center justify-end gap-3 text-sm">
                  <div className="hidden md:block">Section Time remaining</div>
                  <div className="flex justify-end">
                    <div className="flex gap-1">
                      <div>{completed ? "00" : hours}</div>
                      <div>:</div>
                      <div>{completed ? "00" : minutes}</div>
                      <div>:</div>
                      <div>{completed ? "00" : seconds}</div>
                    </div>
                  </div>
                  {/* <ChevronDownIcon className="w-3 h-3" /> */}
                </div>
              );
            }}
            date={sectionTimerEnd}
            onMount={({ completed }) => {
              if (completed) {
                endSectionHandler("timer");
              }
            }}
            onComplete={() => {
              endSectionHandler("timer");
            }}
          />
        )}
      </div>
    )
  );
}

export default AssessmentTimer;
