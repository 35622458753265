import React from "react";
import PDFViewer from "../components/PDFViewer";
import FileViewer from "react-file-viewer";

function FileViewerComponent({ type, url }) {
  switch ((type ?? "").toLowerCase()) {
    case "pdf":
      return <PDFViewer key={url} url={url} />;
    case "jpeg":
    case "jpg":
    case "png":
      return (
        <img
          key={url}
          src={url}
          className="w-full object-contain"
          alt="preview"
        />
      );

    case "doc":
    case "docx":
      return (
        <FileViewer
          fileType={type}
          filePath={url}
          // errorComponent={CustomErrorComponent}
          onError={(err) => {
            console.log("err", err);
          }}
        />
      );

    default:
      return <div>Preview not available</div>;
  }
}

export default FileViewerComponent;
