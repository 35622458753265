import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdClose } from "react-icons/md";

export default function Modal({
  open,
  onClose = () => {},
  children,
  title = "",
  className = "",
  fullScreen = false,
  closeManually = true,
  hideCloseButton = false,
  showTitle = true,
}) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeManually ? () => {} : onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`w-full overflow-hidden text-left align-middle transition-all transform ${
                fullScreen ? "h-screen" : "p-4"
              } max-h-screen flex flex-col ${className}`}
            >
              {showTitle && (
                <div className="flex justify-between px-5 py-3 text-white bg-gray-800 rounded-t-md">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white"
                  >
                    {title}
                  </Dialog.Title>
                  {!hideCloseButton && (
                    <button onClick={onClose}>
                      <MdClose className="w-6 h-6" />
                    </button>
                  )}
                </div>
              )}
              <div
                className={`max-h-full flex-col ${
                  fullScreen ? "flex flex-grow" : "p-5"
                } overflow-y-auto bg-white rounded-md`}
              >
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
