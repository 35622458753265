/* eslint-disable jsx-a11y/media-has-caption */
import { ReactAudioRecorder } from "@/components/attempts/questions/mediaRecorderAudio";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import { useCallback, useState } from "react";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { BiRefresh, BiStopCircle } from "react-icons/bi";
import { FaPauseCircle } from "react-icons/fa";
import { RiPlayCircleLine, RiRecordCircleLine } from "react-icons/ri";
import { twMerge } from "tailwind-merge";
import AudioS3 from "./AudioS3";
import { uploadToS3 } from "@/utilities/s3Helpers";
import { useAuth } from "@/components/AuthProvider";
import { toast } from "react-hot-toast";
import { useQuestion } from "../TestAttemptController";

const RecorderStates = {
  UNINITIALIZED: 0,
  RECORDING: 1,
  PAUSED: 2,
  FINISHED: 3,
};

function AudioType({ save, saving }) {
  const { isLast, test_section_question } = useQuestion();
  const { watch, setValue } = useFormContext();
  const [uploading, setUploading] = useState(false);
  const [state, setState] = useState(null);
  const values = watch();
  const { token } = useAuth();
  const [error, setError] = useState(null);
  const uploadBlob = useCallback(async (blob, duration) => {
    setUploading(true);
    try {
      const path = `audio_submissions/${new Date().getTime()}.wav`;
      // setTimeout(() => {
      //   setError(true);
      //   setUploading(false);
      // }, 1000);

      try {
        setUploading(true);
        const resData = await uploadToS3({
          path,
          token: token.current,
          file: new File([blob], "audio.wav"),
          type: "audio/wav",
        });
        setValue("answer", {
          answer: resData.path,
          path,
        });
        save(false, true);
        setUploading(false);
      } catch (error) {
        console.log("error", error);
        toast.error(
          error?.response?.data?.error?.message ?? "Failed to Upload"
        );
        setError(true);
        setUploading(false);
      }
    } catch (error) {
      setError(true);
      setUploading(false);
    }
  }, []);
  return (
    <div className="flex flex-col w-full max-h-screen px-0 pt-16 pb-20">
      <ReactAudioRecorder
        {...{
          askPermissionOnMount: true,
          onStop: (blob, duration) => {
            uploadBlob(blob, duration);
            setState({ blob, duration });
          },
          onPause: () => {
            setValue("status", "paused");
          },
          onResume: () => {
            setValue("status", "recording");
            setState(null);
          },
          onStart: () => {
            setValue("status", "recording");
            setState(null);
          },
        }}
      >
        {({
          startRecording,
          stopRecording,
          recordingState,
          duration,
          pauseRecording,
          resumeRecording,
          url,
        }) => {
          return (
            <div className="flex flex-col gap-5 p-5 bg-slate-100">
              <div className="flex flex-col-reverse md:flex-row md:gap-3 md:justify-between md:items-center">
                <div>Record your answer here</div>
              </div>
              <div className="flex items-center gap-5">
                {recordingState === RecorderStates.RECORDING ||
                recordingState === RecorderStates.PAUSED ? (
                  <div className="flex gap-1 text-3xl font-semibold">
                    <span>
                      {`${Math.floor(duration / 60)}`.padStart(2, "0")}
                    </span>
                    <span>:</span>
                    <span>
                      {`${Math.floor(duration % 60)}`.padStart(2, "0")}
                    </span>
                  </div>
                ) : null}
                {recordingState === RecorderStates.UNINITIALIZED ? (
                  values.answer?.answer ? (
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          startRecording();
                        }}
                      >
                        <BiRefresh className="w-12 h-12 text-red-600" />
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          startRecording();
                        }}
                      >
                        <RiRecordCircleLine className="w-12 h-12 text-red-600" />
                      </button>
                    </div>
                  )
                ) : null}
                {recordingState === RecorderStates.RECORDING ? (
                  <div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        pauseRecording();
                      }}
                    >
                      <FaPauseCircle className="w-12 h-12 text-red-600" />
                    </button>
                  </div>
                ) : null}
                {recordingState === RecorderStates.PAUSED ? (
                  <div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        resumeRecording();
                      }}
                    >
                      <RiPlayCircleLine className="w-12 h-12 text-red-600" />
                    </button>
                  </div>
                ) : null}
                {recordingState === RecorderStates.FINISHED ? (
                  <div>
                    {!error ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (!uploading) startRecording();
                        }}
                        className="flex items-center gap-2"
                      >
                        <BiRefresh
                          className={`w-12 h-12 text-red-600 ${
                            uploading ? "animate-spin" : ""
                          }`}
                        />
                        {uploading ? "Uploading..." : ""}
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (!uploading)
                            uploadBlob(state.blob, state, duration);
                        }}
                        className="flex items-center justify-center gap-2 px-5 py-2 text-white bg-yellow-600 rounded-md"
                      >
                        {uploading && (
                          <BiRefresh className={`w-5 h-5 animate-spin`} />
                        )}
                        {uploading
                          ? "Uploading..."
                          : "Failed To upload, Try Again?"}
                      </button>
                    )}
                  </div>
                ) : null}
                {recordingState === RecorderStates.RECORDING ||
                recordingState === RecorderStates.PAUSED ? (
                  <div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        stopRecording();
                      }}
                    >
                      <BiStopCircle className="w-12 h-12 text-red-600" />
                    </button>
                  </div>
                ) : null}
                {values.answer?.answer &&
                recordingState === RecorderStates.UNINITIALIZED ? (
                  <div>
                    <AudioS3 path={values.answer.answer} />
                  </div>
                ) : url && recordingState !== RecorderStates.RECORDING ? (
                  <div>
                    <audio controls controlsList="nodownload">
                      <source src={url} />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
      </ReactAudioRecorder>
      <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
        <button
          onClick={() => save(true, true)}
          type="button"
          className={twMerge(
            `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
          )}
          disabled={saving}
        >
          {saving ? <ArrowPathIcon className="w-5 h-5 animate-spin" /> : null}
          {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
        </button>
        <Countdown
          autoStart={true}
          renderer={() => {
            return (
              <span className="text-xs">
                {`Updated ${
                  test_section_question?.test_question_submissions?.[0]
                    ? formatDistanceToNowStrict(
                        new Date(
                          test_section_question?.test_question_submissions?.[0].updated_at
                        ),
                        { addSuffix: true }
                      )
                    : "never"
                }`}
              </span>
            );
          }}
          date={addDays(new Date(), 1)}
          intervalDelay={5000}
        />
      </div>
    </div>
  );
}

export default AudioType;
