import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { useAuth } from "../AuthProvider";
import MarkdownRender from "../markdown/MarkdownRender";
import { produce } from "immer";

const QuestionDisplayComponent = React.memo(function QuestionDisplayComponent({
  is_marked,
  title,
  test_attempt_id,
  test_section_id,
  test_section_question_id,
  currentQuestion,
  section,
  test_section_question,
  hide_marks,
}) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { mutate: markUnmarkQuestion, isLoading: changingQuestionMarkStatus } =
    useMutation(
      (variables) =>
        axios
          .post(
            `${FUNCTION_BASE_URL}/markUnmarkQuestion?attempt_id=${test_attempt_id}`,
            variables,
            {
              headers: {
                Authorization: `Bearer ${auth.token.current}`,
              },
              timeout: 30000,
            }
          )
          .then((res) => res.data),
      {
        onSuccess: async (data) => {
          if (data?.error) {
            toast.error(data?.error?.message || "Something went wrong");
          } else {
            const { insert_test_question_submissions_one } = data;
            if (insert_test_question_submissions_one.is_marked) {
              toast.success("Question Bookmarked");
            } else {
              toast.success("Question Unmarked");
            }
            await queryClient.cancelQueries([
              "assessment",
              "attempt",
              test_section_id,
            ]);
            queryClient.setQueryData(
              ["assessment", "attempt", test_section_id],
              (attemptData) => {
                if (!attemptData) {
                  return attemptData;
                }
                return produce(attemptData, (draft) => {
                  const question = draft.test_section_questions.find(
                    (el) =>
                      el.id ===
                      insert_test_question_submissions_one.test_section_question_id
                  );
                  if (question) {
                    if (question.test_question_submissions[0]) {
                      question.test_question_submissions[0].is_marked =
                        insert_test_question_submissions_one.is_marked ??
                        question?.test_question_submissions?.[0]?.is_marked;
                    } else {
                      question.test_question_submissions[0] = {
                        id: insert_test_question_submissions_one.id,
                        is_marked:
                          insert_test_question_submissions_one.is_marked,
                        answer: insert_test_question_submissions_one.answer,
                        updated_at:
                          insert_test_question_submissions_one.updated_at,
                        time_taken:
                          insert_test_question_submissions_one.time_taken,
                        is_corrected:
                          insert_test_question_submissions_one.is_corrected,
                      };
                    }
                  }

                  return draft;
                });
              }
            );
          }
        },
        onError: () => {
          toast.error("Bookmarking failed, please refresh to try again.");
        },
      }
    );
  return (
    <div className="flex flex-col flex-grow gap-2 mb-6 ">
      <div className="flex justify-between px-5 md:px-8 bg-slate-100 md:bg-white">
        <div className="flex gap-2">
          <div className="self-center font-medium">{`Question ${
            currentQuestion + 1
          }`}</div>
          <div className="flex gap-2">
            {!hide_marks ? (
              <div className="self-center">
                <span className="inline-block p-1 text-xs text-center bg-gray-200 rounded ">
                  {`${test_section_question.max_marks} ${
                    test_section_question.max_marks > 1 ? "Marks" : "Mark"
                  }`}
                </span>
                {section.penalty > 0 && (
                  <span className="inline-block p-2 text-sm text-center bg-gray-200 rounded ">
                    {`- ${section.penalty} Marks (If incorrect)`}
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <button
          className={`${
            is_marked ? "text-yellow-600" : ""
          } flex items-center justify-center h-12 gap-2 text-sm font-semibold tracking-wider text-gray-800 transition duration-200 ease-out sm:px-0 sm:w-40 focus:outline-none`}
          onClick={(e) => {
            e.preventDefault();
            markUnmarkQuestion({
              is_marked: !is_marked,
              test_attempt_id,
              test_section_id,
              test_section_question_id,
            });
          }}
        >
          {changingQuestionMarkStatus ? (
            <>
              <div className="w-6 h-6 spinner-grow"></div>
              <div>Wait...</div>
            </>
          ) : (
            <>
              <div
                className={`inline-block align-middle text-lg ${
                  is_marked ? "text-yellow-600 object-fill fill-current" : ""
                }`}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18,2H6C4.897,2,4,2.897,4,4v5.276V10v12l8-4.572L20,22V10V9.276V4C20,2.897,19.103,2,18,2z M18,18.553l-6-3.428l-6,3.428 V10V9.276V4h12v5.276V10V18.553z"></path>
                </svg>
              </div>
              <div>
                {/* {test_section_question
                                    .test_question_submissions[0] &&
                                  test_section_question
                                    .test_question_submissions[0].is_marked
                                    ? "Unmark"
                                    : "Mark"} */}
                Revisit Later
              </div>
            </>
          )}
        </button>
      </div>
      <MarkdownRender className="px-5 mt-5 md:p-8 markdown">
        {title}
      </MarkdownRender>
    </div>
  );
});

export default QuestionDisplayComponent;
