import FileUpload from "@/components/attempts/questions/fileUpload";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "@hookform/error-message";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import { useRef } from "react";
import Countdown from "react-countdown";
import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import {
  useAssessment,
  useAttempt,
  useQuestion,
} from "../TestAttemptController";
import { produce } from "immer";

function UploadType({ save, saving }) {
  const ref = useRef([]);
  const { isLast, test_section_question } = useQuestion();
  const { id: test_id } = useAssessment();
  const { id: attempt_id } = useAttempt();

  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const values = watch();

  return (
    <div className="flex flex-col w-full gap-3 overflow-y-auto">
      <div className="px-2 py-6 scrollBar sm:px-0">
        <div>
          <div className="text-gray-700 md:px-5">
            <div className="flex flex-col gap-5">
              {test_section_question.question.data.requiredFiles?.map(
                (file, index) => {
                  return (
                    <div
                      key={`reuiredFiles${index}`}
                      className="flex flex-col p-5 bg-white rounded-md shadow-md"
                    >
                      <div className="font-medium text-center ">
                        {file.title || file.name}
                      </div>
                      <div>
                        <Controller
                          name={`answer.answer[${index}].files`}
                          render={({ field: { name, onChange, value } }) => {
                            // return (
                            //   <button
                            //     type="button"
                            //     onClick={() => {
                            //       onChange([
                            //         {
                            //           file: "university/cd3c1b6b-6d2b-424f-8fa4-50ad0c694363/users/683b2aff-0822-456d-b7b5-16622dccf202/file_submissions/9d1fc701-bd27-48ae-8e9d-62a41bfcc350/d8d5e970-34ba-47d8-bd08-384fef1236af/40e2009e-fd65-4708-905f-420be3c8acdd/1691323475943-ec2.pdf",
                            //           ext: "pdf",
                            //           type: "application/pdf",
                            //           name: "ec2.pdf",
                            //         },
                            //       ]);
                            //     }}
                            //   >
                            //     asdfasdf
                            //   </button>
                            // );
                            return (
                              <FileUpload
                                key={`${test_section_question.question.id}${index}`}
                                ref={(element) =>
                                  (ref.current[index] = element)
                                }
                                test_id={test_id}
                                attempt_id={attempt_id}
                                fileConfig={file}
                                files={value}
                                maxFiles={
                                  (file.maxFiles ?? 1) -
                                  (values?.answer?.answer?.[index]?.length ?? 0)
                                }
                                name={name}
                                setFileUrl={(response) => {
                                  console.log("response", response);
                                  setValue(
                                    "answer",
                                    produce(values.answer, (draft) => {
                                      if (!draft) {
                                        draft = {};
                                      }
                                      if (
                                        !draft.answer ||
                                        draft.answer.length === 0
                                      ) {
                                        draft.answer =
                                          test_section_question.question.data.requiredFiles.map(
                                            () => ({ files: [] })
                                          );
                                      }
                                      draft.answer[index].files = [
                                        ...(response ?? []),
                                      ];
                                      return draft;
                                    })
                                  );
                                  // onChange([...(response ?? [])]);
                                }}
                                test_section_question_id={
                                  test_section_question.id
                                }
                                index={index}
                              />
                            );
                          }}
                        />
                      </div>

                      <div>
                        <textarea
                          className="w-full p-2 overflow-y-auto text-sm border-2 rounded-md"
                          spellCheck={false}
                          rows={5}
                          placeholder="You can write your comments here if any."
                          onChange={(e) => {
                            setValue(
                              "answer",
                              produce(values.answer, (draft) => {
                                if (!draft) {
                                  draft = {};
                                }
                                if (
                                  !draft.answer ||
                                  draft.answer.length === 0
                                ) {
                                  draft.answer =
                                    test_section_question.question.data.requiredFiles.map(
                                      () => ({ files: [] })
                                    );
                                }
                                draft.answer[index].description =
                                  e.target.value;
                                return draft;
                              })
                            );
                          }}
                          name={`answer.answer[${index}].description`}
                          value={values?.answer?.answer?.[index]?.description}
                          onPaste={(e) => {
                            e.preventDefault();
                            toast.error("Paste not allowed.");
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            toast.error("Copy not allowed.");
                            return false;
                          }}
                          onCut={(e) => {
                            e.preventDefault();
                            toast.error("Cut not allowed.");
                            return false;
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          component="div"
                          className="text-red-600"
                          name={`answer.answer[${index}].description`}
                        />
                      </div>
                    </div>
                  );
                }
              )}
              {/* {test_section_question.question.data.requiredFiles?.map(
                          (file) => {
                            return <div></div>;
                          }
                        )} */}
            </div>
          </div>
          <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
            <button
              onClick={() => save(true, true)}
              type="button"
              className={twMerge(
                `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
              )}
              disabled={saving}
            >
              {saving ? (
                <ArrowPathIcon className="w-5 h-5 animate-spin" />
              ) : null}
              {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
            </button>
            <Countdown
              autoStart={true}
              renderer={() => {
                return (
                  <span className="text-xs">
                    {`Updated ${
                      test_section_question?.test_question_submissions?.[0]
                        ? formatDistanceToNowStrict(
                            new Date(
                              test_section_question?.test_question_submissions?.[0].updated_at
                            ),
                            { addSuffix: true }
                          )
                        : "never"
                    }`}
                  </span>
                );
              }}
              date={addDays(new Date(), 1)}
              intervalDelay={5000}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadType;
