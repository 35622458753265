import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { useAssessment } from "./TestAttemptController";
import { Tooltip as ReactTooltip } from "react-tooltip";

function QuestionNavigation({
  setCurrentQuestion,
  saveCurrentPosition,
  currentQuestion,
  questions_map,
  questionData,
}) {
  const { disable_question_navigation } = useAssessment();
  const limit = 5;
  const questions = useMemo(() => {
    const q = questionData?.test_section_questions ?? [];
    return q.map((_, i) => q[questions_map[i]]);
  }, [questionData, questions_map]);
  const [page, setPage] = useState(0);

  const pageCount = useMemo(() => {
    return Math.floor(questions.length / limit);
  }, [questions]);
  useEffect(() => {
    if (currentQuestion >= 0) {
      setPage(Math.floor(currentQuestion / limit));
    }
  }, [currentQuestion]);
  return (
    <div>
      {disable_question_navigation && (
        <div className="flex items-center justify-center gap-1 p-1 mb-2 text-xs text-center bg-yellow-300">
          <InformationCircleIcon className="w-3 h-3 " /> Question navigation is
          disabled
        </div>
      )}
      <ReactTooltip
        hidden={!disable_question_navigation}
        id="my-tooltip-2"
        place="top"
        variant="info"
        content="Question navigation is disabled, so you cannot change questions during this assessment."
      />
      <div className="flex items-center gap-2">
        {page > 0 && (
          <button
            onClick={() => {
              setPage((e) => e - 1);
            }}
            className="flex-shrink-0"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
        )}
        <div
          id="question_list"
          className="flex items-center flex-grow-0 w-full max-w-[12rem] gap-2 overflow-x-auto hide-scrollbar"
        >
          {questions.slice(page * limit, (page + 1) * limit).map((el, i) => {
            return (
              <button
                data-tooltip-id="my-tooltip-2"
                key={`qu${i}`}
                className={twMerge(
                  "flex-shrink-0 w-8 h-8 bg-white border rounded",
                  el.is_marked
                    ? "bg-yellow-600 border border-yellow-600 bg-opacity-25"
                    : "",
                  el.test_question_submissions[0]?.answer &&
                    el.test_question_submissions[0].answer.answer !== ""
                    ? "bg-green-600 border border-green-600 bg-opacity-25"
                    : "",
                  currentQuestion === page * limit + i
                    ? "border-t-4 border-t-indigo-600"
                    : "",
                  disable_question_navigation ? "cursor-not-allowed" : ""
                )}
                disabled={disable_question_navigation}
                onClick={() => {
                  if (!disable_question_navigation) {
                    setCurrentQuestion(page * limit + i);
                    saveCurrentPosition(el.id);
                  } else {
                    toast.error("Navigation is not allowed");
                  }
                }}
              >
                {page * limit + i + 1}
              </button>
            );
          })}
        </div>
        {page < pageCount && (
          <button
            onClick={() => {
              setPage((e) => e + 1);
            }}
            className="flex-shrink-0"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
}

export default QuestionNavigation;
