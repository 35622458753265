import app from "@/config/firebase";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "@hookform/error-message";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { AiOutlineFile } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";

function UploadTypeFormField({ name, field, data }) {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const value = watch(name);
  const [isUploading, setIsUploading] = useState(false);
  const t = useMemo(() => {
    return new Date().getTime();
  }, []);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
    useDropzone({
      accept: {
        [field.fileType]: [],
      },
      multiple: false,
      maxSize: 2000000,
      onDropRejected: (files) => {
        toast.error(files[0].errors[0].message);
      },
      onDropAccepted: (files) => {
        const storage = getStorage(app);
        const newPath = `${data.id}/${t}_${files[0].name}`;
        setIsUploading(true);
        const storageRef = ref(storage, newPath);

        uploadBytes(storageRef, files[0], {
          customMetadata: {
            name: files[0].name,
            size: files[0].size,
          },
        })
          .then(() => getDownloadURL(storageRef))
          .then((downloadURL) => {
            return setValue(name, downloadURL);
          })
          .finally(() => {
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);
            console.log(error);
            toast.error("Upload failed");
          });
      },
      disabled: isUploading,
    });
  return (
    <div className={""}>
      <label
        htmlFor={field?.name}
        className="block text-sm font-medium text-gray-700"
      >
        {field?.label ?? field?.name}
        {field.isRequired && <span className="text-red-700">*</span>}
      </label>
      {!value && (
        <>
          <div
            {...getRootProps({
              className:
                "dropzone rounded-md border border-dashed border-gray-400 focus:outline-none",
            })}
          >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center justify-center p-5 ">
              <div className="text-center">
                {isDragActive ? (
                  <p>Drop here ...</p>
                ) : (
                  <p>{"Drag 'n' drop here"}</p>
                )}
              </div>
              <div className="">
                <FaPlusCircle className="w-6 h-6" />
              </div>
            </div>
          </div>
          <div className="py-2 text-sm text-right">
            *Please note: Only {field.type} file less than 2 MB is accepted
          </div>
          {isUploading ? (
            <ul>
              {acceptedFiles.map((file) => (
                <li key={file.path} className="flex items-center">
                  <div>{file.path}</div>
                  <div className="w-6 h-6 mr-3 spinner-grow"></div>
                  <div>Uploading</div>
                </li>
              ))}
            </ul>
          ) : null}
        </>
      )}
      {value && (
        <div className="flex items-center gap-2">
          <span>
            <AiOutlineFile className="w-8 h-8 text-gray-600" />
          </span>
          <span>File Uploaded</span>
          <button
            onClick={() => {
              setValue(name, "");
            }}
            className="text-blue-600 cursor-pointer"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      )}

      <ErrorMessage
        errors={errors}
        as="div"
        className="text-sm text-red-600"
        name={field?.name}
      />
    </div>
  );
}

export default UploadTypeFormField;
