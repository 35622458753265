import app from "@/config/firebase";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { ArrowPathIcon, PencilIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiInfoCircle } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";
import { useAuth } from "../AuthProvider";
import { useParams } from "react-router-dom";

const VerifyOTP = ({ data, next }) => {
  const [showOTP, setShowOTP] = useState(false);
  const queryClient = useQueryClient();
  const { token, setToken } = useAuth();
  const schema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Email is required.")
        .email("Please enter a valid email."),
      ...(showOTP
        ? {
            OTP: Yup.string().required("OTP is required."),
          }
        : {}),
    });
  }, [showOTP]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });
  console.log("isValid", isValid);

  const values = watch();
  console.log("values", values);
  const { test_id } = useParams();

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async (values) => {
      const { data: resData } = await axios.post(
        `${FUNCTION_BASE_URL}/verifyOTP?test_id=${test_id}`,
        {
          email: values.email,
          test_id: data.id,
          otp: values.OTP,
        },
        {
          headers: {
            Authorization: `Bearer ${token.current}`,
          },
          timeout: 30000,
        }
      );

      const authApp = getAuth(app);
      authApp.tenantId = resData.tenantId;
      localStorage.setItem("tenantId", resData.tenantId);
      let res;
      if (authApp.currentUser) {
        res = await authApp.signOut().then(() => {
          return signInWithCustomToken(authApp, resData.token);
        });
      } else {
        res = await signInWithCustomToken(authApp, resData.token);
      }

      token.current = await res.user.getIdToken(true);
      return resData;
    },
    {
      onError: (error) => {
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onMutate: async (variables) => {
        if (variables) {
          try {
            await queryClient.cancelQueries(["assessment", "attempt"]);
          } catch (e) {
            console.error(e);
          }
        }
      },
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        } else {
          next();
        }
      },
    }
  );
  const { mutate: generateOTP, isLoading: generatingOTP } = useMutation(
    async (values) => {
      const { data } = await axios.post(
        `${FUNCTION_BASE_URL}/getOTP?test_id=${test_id}`,
        {
          email: values.email,
          test_id: values.test_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token.current}`,
          },
          timeout: 30000,
        }
      );
      return data;
    },
    {
      onError: (error) => {
        console.log("error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onSuccess: (data) => {
        console.log("data", data);
        if (!data?.success) {
          toast.error("Generating OTP failed.");
        } else {
          toast.success("OTP sent to your Email adress");
          setShowOTP(true);
        }
      },
    }
  );

  return (
    <div className="flex flex-col w-full gap-5 px-8 py-8 text-base lg:pr-12 lg:pl-0">
      <div className="flex items-center w-full gap-3 px-5 py-2 rounded bg-slate-100 text-slate-800">
        <BiInfoCircle className="w-5 h-5" />
        <div className="font-medium">Fields marked with * are compulsory</div>
      </div>

      <div className="flex flex-col border rounded-md shadow">
        <div className="flex items-center w-full gap-3 px-5 rounded bg-slate-100 text-slate-800">
          <div className="h-full px-2 py-2 font-medium border-b-4 border-slate-800">
            Verify OTP
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-5 p-5"
        >
          <>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address <span className="text-red-700">*</span>
              </label>
              <div className="flex items-center gap-5 mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  disabled={showOTP}
                  className={twMerge(
                    "block w-full px-3 py-2 disabled:cursor-not-allowed disabled:bg-slate-300 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none bg-slate-50 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                    errors.email ? "border-red-500" : ""
                  )}
                />
                {showOTP && (
                  <button
                    type="button"
                    onClick={() => {
                      setShowOTP(false);
                      setValue("OTP", "");
                    }}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
              </div>
              <ErrorMessage
                errors={errors}
                as="div"
                className="text-sm text-red-600"
                name={`email`}
              />
            </div>

            {showOTP ? (
              <>
                <div>
                  <div>
                    <label
                      htmlFor="OTP"
                      className="block text-sm font-medium text-gray-700"
                    >
                      OTP <span className="text-red-700">*</span>
                    </label>
                    <p className="text-xs font-light text-gray-600">
                      Enter the 6 digit verification code received on your Email
                      ID
                    </p>
                    <div className="mt-1">
                      <input
                        id="OTP"
                        name="OTP"
                        required
                        placeholder="Enter the 6 digit OTP Code"
                        {...register("OTP", {
                          required: "required",
                        })}
                        className={twMerge(
                          "block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none bg-slate-50 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                          errors.OTP ? "border-red-500" : ""
                        )}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      as="div"
                      className="text-sm text-red-600"
                      name={`OTP`}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex items-center justify-end gap-2">
              {/* {!showOTP && (
                <button
                  onClick={() => setShowOTP(true)}
                  type="button"
                  className={twMerge(
                    `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                    generatingOTP
                      ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
                      : ""
                  )}
                  disabled={generatingOTP}
                >
                  {generatingOTP ? (
                    <ArrowPathIcon className="w-5 h-5 animate-spin" />
                  ) : null}
                  Already have an otp
                </button>
              )} */}
              {showOTP ? (
                <div className="flex items-center gap-1 text-sm">
                  {"If you've not received an OTP then"}{" "}
                  <button
                    onClick={() =>
                      generateOTP({
                        email: getValues("email").toLowerCase(),
                        test_id: data.id,
                      })
                    }
                    type="button"
                    className={twMerge(
                      `p-0 bg-white text-blue-600 underline flex items-center text-sm`,
                      showOTP ? "p-0 bg-white text-blue-600 underline" : "",
                      generatingOTP
                        ? "text-gray-900 cursor-not-allowed bg-opacity-75"
                        : ""
                    )}
                    disabled={generatingOTP}
                  >
                    {generatingOTP ? (
                      <ArrowPathIcon className="w-5 h-5 animate-spin" />
                    ) : null}
                    {generatingOTP ? "Please wait..." : "click here to resend."}
                  </button>
                </div>
              ) : (
                <button
                  onClick={() =>
                    generateOTP({
                      email: getValues("email").toLowerCase(),
                      test_id: data.id,
                    })
                  }
                  type="button"
                  className={twMerge(
                    `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                    generatingOTP || !isValid
                      ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
                      : ""
                  )}
                  disabled={generatingOTP || !isValid}
                >
                  {generatingOTP ? (
                    <ArrowPathIcon className="w-5 h-5 animate-spin" />
                  ) : null}
                  {generatingOTP ? "Please wait..." : "Send OTP"}
                </button>
              )}
            </div>

            <div>
              <button
                type="submit"
                className={twMerge(
                  `w-full flex items-center justify-center gap-3 px-5 py-2 text-sm text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
                  isSubmitting || !isValid || !showOTP
                    ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
                    : ""
                )}
                disabled={isSubmitting || !isValid || !showOTP}
              >
                {isSubmitting ? (
                  <ArrowPathIcon className="w-5 h-5 animate-spin" />
                ) : null}
                {isSubmitting ? "Submitting" : "Submit"}
              </button>
            </div>
          </>
        </form>
      </div>
    </div>
  );
};

export default VerifyOTP;
