import MarkdownRender from "@/components/markdown/MarkdownRender";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import randomSeed from "random-seed";
import { useMemo } from "react";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { BiEraser } from "react-icons/bi";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import {
  useAssessment,
  useAttempt,
  useQuestion,
} from "../TestAttemptController";
import { produce } from "immer";

function MultipleMCQType({ save, saving }) {
  const { isLast, test_section_question } = useQuestion();
  const { randomize_options } = useAssessment();
  const { id: attempt_id } = useAttempt();
  const options = useMemo(() => {
    const tempOptions = test_section_question.question.data.options.map(
      (el, index) => ({
        option: el,
        index,
      })
    );
    if (randomize_options) {
      const math = randomSeed.create(attempt_id);
      tempOptions.sort(() => 0.5 - math.random());
    }
    return tempOptions;
  }, [
    test_section_question.question.data.options,
    randomize_options,
    attempt_id,
  ]);

  const { watch, setValue } = useFormContext();

  const values = watch();
  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex items-center justify-between mt-5">
        <div>Choose the best option</div>
        <button
          onClick={() => setValue("answer", { answer: null })}
          className="flex items-center gap-2 text-sm"
        >
          <BiEraser className="w-4 h-4" />
          Clear Response
        </button>
      </div>
      <div className="flex flex-col gap-5 p-5">
        {options.map(({ option, index }) => {
          return (
            <button
              key={`option${index}`}
              className={` p-5 flex justify-start border items-center shadow-r1 cursor-pointer transition-colors duration-100 ease-in-out rounded-md ${
                values?.answer?.answer?.[`option${index}`]
                  ? "bg-green-200 border-green-600 border-2"
                  : " bg-white"
              } `}
              onClick={(e) => {
                e.preventDefault();

                setValue(
                  `answer`,
                  produce(values.answer, (draft) => {
                    if (!draft) {
                      draft = {};
                    }
                    if (!draft.answer) {
                      draft.answer = {
                        [`option${index}`]: true,
                      };
                    } else {
                      draft.answer[`option${index}`] = draft?.answer?.[
                        `option${index}`
                      ]
                        ? false
                        : true;
                    }
                    return draft;
                  })
                );
              }}
            >
              <label className="flex items-center justify-center gap-2 ml-5 cursor-pointer">
                {values?.answer?.answer?.[`option${index}`] ? (
                  <MdCheckBox
                    className={`w-4 h-4 flex-shrink-0 flex ${
                      values?.answer?.answer?.[`option${index}`]
                        ? "text-green-500 border-green-600"
                        : ""
                    } `}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    className={`w-4 h-4 flex-shrink-0 flex ${
                      values?.answer?.answer?.[`option${index}`]
                        ? "text-green-500 border-green-600"
                        : ""
                    } `}
                  />
                )}
                <MarkdownRender className="markdown">
                  {`${option}`}
                </MarkdownRender>
              </label>
            </button>
          );
        })}
      </div>
      <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
        <button
          onClick={() => save(true, true)}
          type="button"
          className={twMerge(
            `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
          )}
          disabled={saving}
        >
          {saving ? <ArrowPathIcon className="w-5 h-5 animate-spin" /> : null}
          {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
        </button>
        <Countdown
          autoStart={true}
          renderer={() => {
            return (
              <span className="text-xs">
                {`Updated ${
                  test_section_question?.test_question_submissions?.[0]
                    ? formatDistanceToNowStrict(
                        new Date(
                          test_section_question?.test_question_submissions?.[0].updated_at
                        ),
                        { addSuffix: true }
                      )
                    : "never"
                }`}
              </span>
            );
          }}
          date={addDays(new Date(), 1)}
          intervalDelay={5000}
        />
      </div>
    </div>
  );
}

export default MultipleMCQType;
