import { useAuth } from "@/components/AuthProvider";
import AssessmentOverview from "@/components/welcome/AssessmentOverview";
import PreAssessment from "@/components/welcome/PreAssessment";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
function Welcome({ auth = false }) {
  const { token } = useAuth();
  const [proceed, setProceed] = useState(false);
  let { test_id } = useParams();

  const { isLoading, error, data } = useQuery(
    ["assessment", "welcome"],
    async () => {
      try {
        const { data, status } = await axios.get(
          `${FUNCTION_BASE_URL}/getAssessmentInfo?test_id=${test_id}`,
          {
            headers: {
              Authorization: `Bearer ${token.current}`,
            },
            timeout: 30000,
          }
        );
        return data;
      } catch (error) {
        throw new Error(error.response.data.error.message);
      }
    }
  );

  const testDuration = useMemo(() => {
    if (data) {
      return data.duration;
    }
    return 0;
  }, [data]);

  const sectionCount = useMemo(() => {
    if (data) {
      return data.test_sections.length;
    }
    return 0;
  }, [data]);

  const questionStats = useMemo(() => {
    if (data) {
      return data.test_sections.reduce(
        (prev, cur) => {
          return {
            maxMarks:
              prev.maxMarks +
              cur.test_section_questions_aggregate.aggregate.sum.max_marks,
            count:
              prev.count + cur.test_section_questions_aggregate.aggregate.count,
          };
        },
        {
          maxMarks: 0,
          count: 0,
        }
      );
    }
    return {
      maxMarks: 0,
      count: 0,
    };
  }, [data]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full py-16 text-2xl font-semibold">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );
  }
  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }

  if (auth && !token.current) {
    throw new Error("Your token is expired");
  }

  if (data?.error) {
    throw new Error(data?.error);
  }
  if (proceed) {
    return (
      <PreAssessment
        {...{ data, questionStats, sectionCount, testDuration, auth }}
      />
    );
  }
  return (
    <AssessmentOverview
      {...{ data, questionStats, sectionCount, testDuration, setProceed, auth }}
    />
  );
}

export default Welcome;
