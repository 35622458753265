import { useAuth } from "@/components/AuthProvider";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import addMinutes from "date-fns/addMinutes";
import addSeconds from "date-fns/addSeconds";
import min from "date-fns/min";
import randomSeed from "random-seed";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiAlarmExclamation } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import SectionSelection from "../SectionSelection";
import Modal from "../ui/Modal";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { isPast } from "date-fns";
import { isMobile } from "react-device-detect";
import Button from "../ui/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const AttemptContext = createContext({});
const AssessmentContext = createContext({});
const QuestionContext = createContext({});

function TestAttemptController({ children }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [focusChangedWarning, setFocusChangedWarning] = useState(false);
  const questionRef = useRef();
  const auth = useAuth();
  const [sectionChoose, setSectionChoose] = useState(false);
  const [initialFullScreen, setInitialFullScreen] = useState(false);
  const navigate = useNavigate();
  const [nextSectionSelected, setNextSectionSelected] = useState(null);
  const { attempt_id } = useParams();
  const { isLoading, error, data, refetch } = useQuery(
    ["assessment", "attempt"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/getAttemptData?attempt_id=${attempt_id}`, {
        headers: {
          Authorization: auth.token.current
            ? `Bearer ${auth.token.current}`
            : null,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        }),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }

  const deviceNotAllowed = useMemo(() => {
    if (data?.tests_by_pk && data?.tests_by_pk?.disable_mobile) {
      return isMobile;
    }
    return false;
  }, [data]);
  const { currentSection, currentSectionId } = useMemo(() => {
    if (
      data &&
      data.test_attempts_by_pk &&
      data.test_attempts_by_pk.current_section_id
    ) {
      const i = data.tests_by_pk.test_sections.find(
        (el) => el.id === data.test_attempts_by_pk.current_section_id
      );

      if (i) {
        return {
          currentSection: i,
          currentSectionId: data.test_attempts_by_pk.current_section_id,
        };
      }
    }
    return {};
  }, [data]);
  const {
    isLoading: questionIsLoading,
    error: questionError,
    data: questionData,
    refetch: questionRefetch,
    isFetching: questionIsFetching,
  } = useQuery(
    [
      "assessment",
      "attempt",
      data?.test_attempts_by_pk?.current_section_id ?? "NA",
    ],
    () =>
      fetch(
        `${FUNCTION_BASE_URL}/getCurrentSectionQuestions?attempt_id=${attempt_id}`,
        {
          headers: {
            Authorization: auth.token.current
              ? `Bearer ${auth.token.current}`
              : null,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        }),
    {
      enabled: Boolean(data?.test_attempts_by_pk?.current_section_id),
      onSuccess: (resData) => {
        if (!resData || resData.error) {
          return;
        }
        const { test_attempts_by_pk, tests_by_pk } = data;
        if (test_attempts_by_pk && tests_by_pk) {
          setCurrentQuestion(() => {
            if (
              !test_attempts_by_pk.is_completed &&
              test_attempts_by_pk.current_test_section_question_id
            ) {
              const questions = [...resData.test_section_questions];
              if (tests_by_pk.randomize_questions) {
                const math = randomSeed.create(test_attempts_by_pk.id);
                questions.sort(() => 0.5 - math.random());
              }
              const index = questions.findIndex(
                (q) =>
                  q.id === test_attempts_by_pk.current_test_section_question_id
              );
              return index < 0 ? 0 : index;
            }
            return 0;
          });
        }
      },
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (data?.tests_by_pk?.disable_copy_paste) {
      document.addEventListener("paste", (event) => event.preventDefault());
      document.addEventListener("copy", (event) => event.preventDefault());
      document.addEventListener("cut", (event) => event.preventDefault());
      document.addEventListener("keydown", (event) => {
        const { keyCode, ctrlKey, metaKey } = event;
        if (
          (keyCode === 67 || keyCode === 86 || keyCode === 88) &&
          (metaKey || ctrlKey)
        ) {
          toast.error("Copy paste is disabled");
          event.preventDefault();
        }
      });
      document.addEventListener("contextmenu", (ev) => {
        ev.preventDefault();
        toast.error("Right Click is disabled");
      });
    }
    return () => {
      document.removeEventListener("keydown", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("contextmenu", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("paste", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("copy", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("cut", function (event) {
        console.log("Key down event triggered:", event.key);
      });
    };
  }, [data]);
  const section_index = useMemo(() => {
    if (data && data.test_attempts_by_pk) {
      const i = data.tests_by_pk.test_sections.findIndex(
        (el) => el.id === data.test_attempts_by_pk.current_section_id
      );
      return i >= 0 ? i : 0;
    }
    return -1;
  }, [data]);

  const section_wise_time_spent = useMemo(() => {
    if (!data || data.error || !questionData) {
      return {};
    }
    return (data?.tests_by_pk?.test_sections ?? []).reduce(
      (prev, test_section) => {
        if (test_section.id === currentSectionId) {
          const time_taken = questionData.test_section_questions.reduce(
            (prev2, section_question) => {
              return (
                prev2 +
                (section_question?.test_question_submissions?.[0]?.time_taken ||
                  0)
              );
            },
            0
          );

          return {
            ...prev,
            [test_section.id]: time_taken,
          };
        }
        return {
          ...prev,
          [test_section.id]:
            test_section.test_attempt_sections[0]?.test_attempt_section_overview
              ?.time_taken ?? 0,
        };
      },
      {}
    );
  }, [data, questionData, currentSectionId]);

  const allQuestionAttempted = useMemo(() => {
    if (!questionData || questionData.error) {
      return false;
    }
    return questionData.test_section_questions.every(
      (el) =>
        el.test_question_submissions[0]?.answer &&
        el.test_question_submissions[0].answer.answer !== ""
    );
  }, [questionData]);

  const testTimerEnd = useMemo(() => {
    if (data?.tests_by_pk && !data.tests_by_pk.isPractice) {
      const minimum = min([
        (() => {
          if (data.tests_by_pk.pause_when_inactive) {
            const total_time = Object.values(section_wise_time_spent).reduce(
              (prev, cur) => prev + cur,
              0
            );
            return addSeconds(
              new Date(),
              data.tests_by_pk.duration * 60 - total_time
            );
          } else {
            return addMinutes(
              new Date(data.test_attempts_by_pk.created_at),
              data.tests_by_pk.duration
            );
          }
        })(),
      ]);
      const testEnd = addMinutes(minimum, data.test_attempts_by_pk.bonus_time);
      if (isPast(testEnd) && data.test_attempts_by_pk.bonus_time > 0) {
        return addMinutes(
          new Date(data.test_attempts_by_pk.bonus_time_given_at),
          data.test_attempts_by_pk.bonus_time
        );
      }
      return testEnd;
    }

    return null;
  }, [section_wise_time_spent, data]);

  const currentSectionAttemptedQuestionsStats = useMemo(() => {
    if (currentSectionId) {
      if (questionData && !questionData.error) {
        return questionData.test_section_questions.reduce(
          (prev, cur) => {
            return {
              attempted:
                prev.attempted +
                (cur.test_question_submissions[0] &&
                cur.test_question_submissions[0].answer &&
                cur.test_question_submissions[0].answer.answer !== ""
                  ? 1
                  : 0),
              total: prev.total + 1,
            };
          },
          {
            attempted: 0,
            total: 0,
          }
        );
      }
    }
    return { attempted: 0, total: 0 };
  }, [questionData, currentSectionId]);

  const questions_map = useMemo(() => {
    if (currentSectionId && questionData && data) {
      const question_count = questionData.test_section_questions.length;
      const question_indexes = Array(question_count)
        .fill()
        .map((_, i) => i);

      if (data.tests_by_pk.randomize_questions) {
        const math = randomSeed.create(data.test_attempts_by_pk.id);
        question_indexes.sort(() => 0.5 - math.random());
      }
      return question_indexes.reduce((prev, cur, i) => {
        return {
          ...prev,
          [i]: cur,
        };
      }, {});
    }
    return null;
  }, [data, currentSectionId, questionData]);

  const { mutate: savePosition } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/savePosition?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onError: (error) => {
        console.log("saveCurrentPosition error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        }
      },
    }
  );

  const { mutate: endTest, isLoading: endTestLoading } = useMutation(
    async (variables) => {
      await axios
        .post(
          `${FUNCTION_BASE_URL}/endTest?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data);
      await refetch();

      if (variables.ending_method !== "timer") {
        navigate(`/attempt/${attempt_id}/complete`);
      }
    },
    {
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        }
        // else {
        //   navigate("/attempt/complete");
        // }
      },

      onError: (error) => {
        console.log("endTest error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ??
              "Ending test failed, please refresh to try again."
          );
        }
      },
    }
  );

  const saveCurrentPosition = (section_question_id) => {
    if (section_question_id) {
      return savePosition({
        attempt_id: data.test_attempts_by_pk.id,
        current_test_section_question_id: section_question_id,
      });
    }
  };

  const { mutate: endSection, isLoading: endSectionLoading } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/endSection?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onError: (error) => {
        console.log("endSection error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ??
              "Finishing section failed, please refresh to try again."
          );
        }
      },
      onSuccess: async () => {
        await refetch();
        setSectionChoose(false);
      },
    }
  );
  const { mutate: nextSection, isLoading: nextSectionLoading } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/nextSection?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onError: (error) => {
        console.log("nextSection error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ??
              "Finishing section failed, please refresh to try again."
          );
        }
      },
      onSuccess: async () => {
        if (error) {
          toast.error(error?.message || "Something went wrong");
        } else {
          toast.success("Section changed successfully.");
        }
        await refetch();
        // window.location.reload();
        setSectionChoose(false);
      },
    }
  );

  const endSectionHandler = useCallback(
    async (ending_method = null, test_section_id) => {
      setCurrentQuestion(0);
      if (questionRef.current?.isDirty) {
        await questionRef.current?.save?.();
      }
      if (ending_method === "timer") {
        return await endSection({
          test_attempt_id: data.test_attempts_by_pk.id,
          test_section_id: data.tests_by_pk.test_sections[section_index].id,
          ending_method,
        });
      } else {
        return await nextSection({
          test_attempt_id: data.test_attempts_by_pk.id,
          test_section_id:
            test_section_id ??
            data.tests_by_pk.test_sections[section_index + 1].id,
          ending_method,
        });
      }
    },
    [data, setCurrentQuestion, nextSection, endSection, section_index]
  );

  const endTestHandler = useCallback(
    async (ending_method = null, rating = null) => {
      if (questionRef.current?.isDirty) {
        await questionRef.current?.save?.();
      }
      return await new Promise((resolve, reject) => {
        endTest(
          {
            test_attempt_id: data.test_attempts_by_pk.id,
            rating,
            ending_method,
          },
          { onSettled: resolve, onError: reject }
        );
      });
    },
    [data, endTest]
  );
  const { mutate: increaseFocusSwitchCount } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/increaseFocusSwitchCount?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onError: (error) => {
        console.log("saveCurrentPosition error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        }
        if (data?.completed) {
          refetch();
        }
      },
    }
  );
  const { mutate: increaseFullscreenChangeCount } = useMutation(
    (variables) =>
      axios
        .post(
          `${FUNCTION_BASE_URL}/increaseFullscreenChangeCount?attempt_id=${attempt_id}`,
          variables,
          {
            headers: {
              Authorization: `Bearer ${auth.token.current}`,
            },
            timeout: 30000,
          }
        )
        .then((res) => res.data),
    {
      onError: (error) => {
        console.log("saveCurrentPosition error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }
      },
      onSuccess: (data) => {
        if (data?.error) {
          toast.error(data?.error?.message || "Something went wrong");
        }
        if (data?.completed) {
          refetch();
        }
      },
    }
  );

  useEffect(() => {
    function increaseFocusSwitchCountHandler() {
      increaseFocusSwitchCount({});
    }
    function showFocusWarning() {
      // toast.error(
      //   "You should not leave the test page while the test is in progress."
      // );
      setFocusChangedWarning(true);
    }
    function onchange(evt) {
      if (evt.type === "blur") {
        debounceFunction1(increaseFocusSwitchCountHandler);
        showFocusWarning();
      } else if (evt.type === "visibilitychange") {
        evt.preventDefault();
        if (!document.hidden) {
          debounceFunction1(increaseFocusSwitchCountHandler);
        } else {
          showFocusWarning();
        }
      } else {
        if (evt) evt.returnValue = "Are you sure you want to close?";
        return "Are you sure you want to close?";
      }
      return (evt.returnValue = "Are you sure you want to exit?");
    }
    var hidden = "hidden";
    var timerId1;
    var debounceFunction1 = function (func, delay = 500) {
      // Cancels the setTimeout method execution
      clearTimeout(timerId1);

      // Executes the func after delay time.
      timerId1 = setTimeout(func, delay);
    };
    var timerId2;
    var debounceFunction2 = function (func, delay = 1500) {
      // Cancels the setTimeout method execution
      clearTimeout(timerId2);

      // Executes the func after delay time.
      timerId2 = setTimeout(func, delay);
    };
    // Standards:
    if (hidden in document)
      document.addEventListener("visibilitychange", onchange);
    else if ((hidden = "mozHidden") in document)
      document.addEventListener("mozvisibilitychange", onchange);
    else if ((hidden = "webkitHidden") in document)
      document.addEventListener("webkitvisibilitychange", onchange);
    else if ((hidden = "msHidden") in document)
      document.addEventListener("msvisibilitychange", onchange);
    // IE 9 and lower:
    else if ("onfocusin" in document)
      document.addEventListener("focusout", onchange);
    // // All others:

    window.addEventListener("blur", onchange);
    window.addEventListener("beforeunload", onchange);

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined)
      onchange({ type: document[hidden] ? "blur" : "focus" });

    return () => {
      if (hidden in document)
        document.removeEventListener("visibilitychange", onchange);
      else if ((hidden = "mozHidden") in document)
        document.removeEventListener("mozvisibilitychange", onchange);
      else if ((hidden = "webkitHidden") in document)
        document.removeEventListener("webkitvisibilitychange", onchange);
      else if ((hidden = "msHidden") in document)
        document.removeEventListener("msvisibilitychange", onchange);
      // IE 9 and lower:
      else if ("onfocusin" in document)
        document.removeEventListener("focusout", onchange);
      // // All others:
      // else {
      window.removeEventListener("blur", onchange);
      window.removeEventListener("beforeunload", onchange);
      // }
    };

    // const tabCloseHandler = function (e) {
    //   e.preventDefault();
    //   if (!document.hidden) {
    //     increaseFocusSwitchCount({
    //       variables: {
    //         id: params.test_attempt_id,
    //       },
    //     });
    //   } else {
    //     toast.error("You should not switch tabs while giving the test.");
    //   }
    // };
    // const focusChangeHandler = function (e) {
    //   e.preventDefault();
    //   if (!document.hidden) {
    //     increaseFocusSwitchCount({
    //       variables: {
    //         id: params.test_attempt_id,
    //       },
    //     });
    //   } else {
    //     toast.error("You should not switch tabs while giving the test.");
    //   }
    //   // return
    // };
    // window.addEventListener("beforeunload", tabCloseHandler);
    // document.addEventListener("visibilitychange", focusChangeHandler);
    // return () => {
    //   window.removeEventListener("beforeunload", tabCloseHandler);
    //   document.removeEventListener("visibilitychange", focusChangeHandler);
    // };
  }, [increaseFocusSwitchCount]);

  const sectionEnded = useMemo(() => {
    return data &&
      currentSection &&
      currentSection?.test_attempt_sections?.[0]?.is_completed
      ? currentSection.test_attempt_sections?.[0]
      : null;
  }, [currentSection, data]);

  const test_section_question = useMemo(() => {
    if (!questionData || questionData.error) {
      return null;
    }
    const t =
      questionData?.test_section_questions?.[questions_map?.[currentQuestion]];
    return t ? t : questionData?.test_section_questions?.[questions_map?.[0]];
  }, [questions_map, currentQuestion, questionData]);

  const isLast = useMemo(() => {
    return (
      currentQuestion ===
      (questionData?.test_section_questions?.length ?? 0) - 1
    );
  }, [currentQuestion, questionData]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  function openFullscreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener(
      "fullscreenchange",
      function () {
        setIsFullScreen(document.fullscreen);
      },
      false
    );

    document.addEventListener(
      "mozfullscreenchange",
      function () {
        setIsFullScreen(document.mozFullScreen);
      },
      false
    );

    document.addEventListener(
      "webkitfullscreenchange",
      function () {
        setIsFullScreen(document.webkitIsFullScreen);
      },
      false
    );

    document.addEventListener(
      "msfullscreenchange",
      function () {
        setIsFullScreen(document.msFullscreenElement);
      },
      false
    );
    return () => {
      document.removeEventListener("fullscreenchange", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("mozfullscreenchange", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("webkitfullscreenchange", function (event) {
        console.log("Key down event triggered:", event.key);
      });
      document.removeEventListener("msfullscreenchange", function (event) {
        console.log("Key down event triggered:", event.key);
      });
    };
  }, []);

  useEffect(() => {
    if (!initialFullScreen) setInitialFullScreen(true);

    if (
      initialFullScreen &&
      data?.tests_by_pk?.strict_fullscreen &&
      !isFullScreen
    ) {
      increaseFullscreenChangeCount({});
    }
  }, [isFullScreen, initialFullScreen, data, increaseFullscreenChangeCount]);
  if (deviceNotAllowed) {
    return (
      <div className="flex justify-center gap-5 py-16">
        <div>Mobile Devices are not allowed for this assessment</div>
      </div>
    );
  }
  // if (data?.test_attempts_by_pk?.is_completed) {
  //   return (
  //     <Navigate to={`/attempt/${data?.test_attempts_by_pk?.id}/complete`} />
  //   );
  // }

  if (data?.test_attempts_by_pk?.is_completed) {
    return (
      <Modal
        open={true}
        onClose={() => {}}
        hideCloseButton={true}
        className="max-w-screen-sm"
        showTitle={false}
      >
        <div className="flex flex-col items-center gap-2">
          <MdClose className="w-12 h-12 p-1 text-red-600 border border-red-600 rounded-full" />
          <div className="text-lg font-medium leading-6 text-gray-900">
            Assessment Over ({data.test_attempts_by_pk.ending_method})
          </div>
          <div className="mt-2">
            <p className="text-gray-500">
              Assessment was ended by {data.test_attempts_by_pk.ending_method}.
              Please proceed to continue.
            </p>
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => navigate(`/attempt/${attempt_id}/complete`)}
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  if (
    data?.test_attempts_by_pk &&
    !data?.test_attempts_by_pk.current_section_id
  ) {
    return <Navigate to={`/chooseSection/${data?.tests_by_pk?.id}`} />;
  }

  if (isLoading || questionIsLoading) {
    console.log(
      "isLoading || questionIsLoading: ",
      isLoading || questionIsLoading
    );
    return (
      <div className="flex items-center justify-center text-2xl font-semibold h-128">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );
  }
  console.log("data", data);
  if (error || questionError || !data || !data.test_attempts_by_pk) {
    console.log(
      "error || questionError || !data || !data.test_attempts_by_pk: ",
      error || questionError || !data || !data.test_attempts_by_pk
    );

    const errorMessage =
      error?.message ??
      error?.error?.message ??
      questionError?.message ??
      questionError?.error?.message ??
      "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }

  if (data?.error) {
    if (data.error.code === "NOT_FOUND") {
      return <Navigate to={`/welcome/${data.test_id}`} />;
    }
    const errorMessage = data.error.message ?? "Error";
    throw new Error(errorMessage);
  }

  if (sectionEnded) {
    return (
      <Modal
        open={true}
        onClose={() => {}}
        hideCloseButton={true}
        className="max-w-screen-sm"
        showTitle={false}
      >
        <div className="flex flex-col items-center gap-2">
          {sectionEnded?.ending_method === "timer" ? (
            <BiAlarmExclamation className="w-24 h-24 p-1 text-red-600" />
          ) : (
            <InformationCircleIcon className="w-24 h-24 text-yellow-600" />
          )}
          <div className="text-lg font-medium leading-6 text-gray-900">
            Section Over ({sectionEnded?.ending_method ?? ""})
          </div>
          <div className="mt-2">
            <p className="text-gray-500">
              Section was ended by {sectionEnded?.ending_method ?? ""}. Please
              proceed to continue.
            </p>
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() =>
                navigate(`/chooseSection/${data?.tests_by_pk?.id}`)
              }
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <>
      <AttemptContext.Provider value={data?.test_attempts_by_pk ?? {}}>
        <Modal
          open={data?.tests_by_pk?.strict_fullscreen && !isFullScreen}
          onClose={() => {}}
          showTitle={false}
          className="max-w-sm"
        >
          <div className="flex flex-col items-center gap-5">
            <div>
              This assessment required your browser window to be in full screen
              mode{" "}
              {data?.tests_by_pk?.fullscreen_check_max
                ? "(Assessment will be forcefully closed if you come out of fullscreen too many times)"
                : ""}
            </div>
            <Button
              className="text-white bg-indigo-600"
              onClick={openFullscreen}
            >
              Enter FullScreen
            </Button>
          </div>
        </Modal>
        <AssessmentContext.Provider value={data?.tests_by_pk ?? {}}>
          <QuestionContext.Provider
            value={{
              isLast,
              test_section_question,
              questionData,
              allQuestionAttempted,
              questions_map,
              currentQuestion,
            }}
          >
            {children({
              data,
              test_section_question,
              endSectionHandler,
              endTestHandler,
              saveCurrentPosition,
              currentSectionAttemptedQuestionsStats,
              testTimerEnd,
              section_index,
              setCurrentQuestion,
              currentQuestion,
              questions_map,
              questionRef,
              endTestLoading,
              nextSectionLoading,
              setSectionChoose,
              isLast,
              section_wise_time_spent,
              allQuestionAttempted,
              questionData,
              currentSection,
              isFullScreen,
              openFullscreen,
              closeFullscreen,
            })}
            <Modal
              open={endTestLoading || nextSectionLoading}
              onClose={() => {}}
              hideCloseButton={true}
              className="max-w-screen-md"
              showTitle={false}
            >
              <div className="flex flex-col items-center w-full max-h-full px-5 py-6 overflow-y-auto bg-white sm:px-12 md:w-110 rounded-b-md">
                <div className="">
                  {endTestLoading ? "Ending Assessment" : "Ending Section"}
                </div>
                <div className="">Loading please wait...</div>
                <div className="text-sm">
                  {"(In case it's taking too long, please refresh the page)"}
                </div>
              </div>
            </Modal>
            <Modal
              open={
                focusChangedWarning && data?.tests_by_pk?.focus_change_track
              }
              onClose={() => {}}
              hideCloseButton={true}
              className="max-w-screen-sm"
              showTitle={false}
            >
              <div className="flex flex-col items-center gap-2">
                <MdClose className="w-12 h-12 p-1 text-red-600 border border-red-600 rounded-full" />
                <div className="text-lg font-medium leading-6 text-gray-900">
                  Focus change detected{" "}
                </div>
                <div className="mt-2">
                  <p className="text-gray-500">
                    You should not leave the test page while the test is in
                    progress.{" "}
                    {data.tests_by_pk.strict_focus
                      ? `Assessment will be forcefully closed if it happens too many times`
                      : ""}
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => setFocusChangedWarning(false)}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={sectionChoose}
              onClose={() => {
                setSectionChoose(false);
              }}
              className="max-w-screen-md"
              title="Choose Section"
            >
              {sectionChoose && (
                <SectionSelection
                  data={data}
                  endSectionHandler={endSectionHandler}
                  nextSectionLoading={nextSectionLoading}
                  onChoose={(id) => {
                    setNextSectionSelected(id);
                  }}
                  onClose={() => setSectionChoose(false)}
                />
              )}
            </Modal>
          </QuestionContext.Provider>
        </AssessmentContext.Provider>
      </AttemptContext.Provider>
    </>
  );
}

export default TestAttemptController;
export const useAttempt = () => useContext(AttemptContext);
export const useAssessment = () => useContext(AssessmentContext);
export const useQuestion = () => useContext(QuestionContext);
