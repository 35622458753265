import SectionSelection from "@/components/welcome/SectionSelection";
import SystemCompatibility from "@/components/welcome/SystemCompatibility";
import UserRegistration from "@/components/welcome/UserRegistration";
import { useMemo, useState } from "react";
import { BiBook, BiLaptop, BiUser } from "react-icons/bi";
import AssessmentInstructions from "./AssessmentInstructions";

function PreAssessment({
  data,
  questionStats,
  sectionCount,
  testDuration,
  auth,
}) {
  console.log("data", data);

  const [step, setStep] = useState(0);
  const steps = useMemo(() => {
    return [
      {
        component: AssessmentInstructions,
        icon: BiLaptop,
      },
      {
        component: SystemCompatibility,
        icon: BiLaptop,
      },
      ...((auth && data.require_registration) || !auth
        ? [
            {
              component: UserRegistration,
              icon: BiUser,
            },
          ]
        : []),
      {
        component: SectionSelection,
        icon: BiBook,
      },
    ];
  }, [data]);
  const CurrentStep = steps[step].component;
  return (
    <div className="flex flex-col w-full min-h-full  bg-slate-800">
      <header className="sticky top-0 z-50 px-16 py-5 text-lg text-white bg-slate-800">
        <div className="flex items-center gap-2">
          {data?.university?.icon && (
            <img
              src={data?.university?.icon}
              alt="Icon"
              className="object-contain w-8 h-8"
            />
          )}

          {data?.university?.name ?? "Meritcurve"}
        </div>
      </header>
      <main className="flex flex-col flex-grow px-8">
        <div className="grid items-start flex-grow grid-cols-1 pb-12 text-xl text-black divide-x-2 rounded-md lg:items-center lg:grid-cols-12">
          <div className="flex-col items-center justify-center flex-grow hidden w-full h-full col-span-5 mt-8 border lg:flex bg-slate-100 rounded-l-md">
            <div className="flex flex-col w-full px-16">
              <div className="text-2xl text-slate-500">Hi,</div>
              <div className="mt-5 text-2xl">Welcome to</div>
              <div className="mt-2 text-4xl">{data.title}</div>
              <hr className="max-w-xs my-12" />
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                {[
                  {
                    key: "Question count:",
                    value: `${questionStats.count} Questions`,
                  },
                  {
                    key: "Total Marks:",
                    value: `${questionStats.maxMarks} Marks`,
                  },
                  {
                    key: "Section count:",
                    value: `${sectionCount} Sections`,
                  },
                  {
                    key: "Test Duration:",
                    value: `${testDuration} Minutes`,
                  },
                ].map((el) => {
                  return (
                    <div className="flex flex-col" key={el.key}>
                      <div className="text-sm text-slate-900">{el.key}</div>
                      <div className="text-lg">{el.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center flex-grow col-span-12 mt-8 bg-white border lg:h-full lg:flex-row lg:col-span-7 rounded-l-md lg:rounded-l-none rounded-r-md">
            <div className="flex items-center -translate-y-1/2 lg:translate-y-0 lg:flex-col lg:-translate-x-1/2">
              {steps.slice(0, 1).map((el) => {
                const Icon = el.icon;
                return (
                  <>
                    <button className="p-2 bg-blue-400 border-2 rounded-full">
                      <Icon className="w-6 h-6 text-white" />
                    </button>
                  </>
                );
              })}
              {steps.slice(1).map((el, i) => {
                const Icon = el.icon;
                return (
                  <>
                    <div
                      className={`lg:w-0 lg:h-12 h-0 w-12 border ${
                        i > step ? "border-white" : "border-blue-400"
                      }`}
                    ></div>
                    <button
                      className={`p-2 ${
                        i < step ? "bg-blue-400" : "bg-white"
                      } border-2 rounded-full`}
                    >
                      <Icon
                        className={`w-6 h-6 ${
                          i < step ? "text-white" : "text-gray-700"
                        }`}
                      />
                    </button>
                  </>
                );
              })}
            </div>
            <CurrentStep
              next={() => {
                setStep((i) => i + 1);
              }}
              data={data}
              auth={auth}
            />
          </div>
        </div>
      </main>
      {/* <footer className="fixed bottom-0 w-full px-16 py-2 text-xs text-center bg-slate-800">
        Meritcurve @ AI SmartLabs LLP
      </footer> */}
    </div>
  );
}

export default PreAssessment;
