import { FUNCTION_BASE_URL } from "@/utilities/constants";
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { addSeconds, isPast } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAuth } from "../AuthProvider";

function SectionSelection({ hideTimer = false }) {
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { test_id } = useParams();
  const { isLoading, error, data } = useQuery(
    ["assessment", "attempt_section"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/getSectionsForSelection?test_id=${test_id}`, {
        headers: {
          Authorization: `Bearer ${token.current}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        })
  );

  const { mutate: startAttempt, isLoading: submitting } = useMutation(
    async (values) => {
      try {
        const { data: resData } = await axios.post(
          `${FUNCTION_BASE_URL}/startAttempt?test_id=${test_id}`,
          {
            test_section_id: values.test_section_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token.current}`,
            },
            timeout: 30000,
          }
        );
        return resData;
      } catch (error) {
        throw new Error(
          error?.message ?? error?.response?.data?.error?.message
        );
      }
    },
    {
      onError: (error) => {
        console.log("error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(error?.message ?? "Something went wrong ");
        }
      },
      onSuccess: (data) => {
        console.log("data", data);
        if (!data?.success) {
          toast.error("Failed to start attempt");
        } else {
          if (data.status === "completed") {
            //Todo:
            navigate(`/attempt/${data?.test_attempt?.id}/complete`);
          } else {
            navigate(`/attempt/${data?.test_attempt?.id}`);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (data && !data.error) {
      if (data.test_attempt) {
        if (data.test_attempt.is_completed) {
          //Todo
          return navigate(`/attempt/${data?.test_attempt?.id}/complete`);
        } else {
          toast("There is an attempt already in progress.");
          return navigate(`/chooseSection/${test_id}`);
        }
      }
      setSelected(data.test_sections[0]?.id);
    }
  }, [data, navigate, test_id]);

  // const attemptedSectionsMap = useMemo(() => {
  //   if (data && !data.error) {
  //     return data.duration;
  //   }
  //   return {};
  // }, [data]);

  const is_test_ended = useMemo(() => {
    if (data && data.test_participant) {
      return isPast(new Date(data.test_participant?.ends_at));
    }
    return true;
  }, [data]);
  const is_test_started = useMemo(() => {
    if (data && data.test_participant) {
      return isPast(new Date(data.test_participant?.start_at));
    }
    return false;
  }, [data]);

  const timerEnd = useMemo(() => {
    return addSeconds(new Date(), 60);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }
  if (!data || data.error) {
    throw new Error(data?.error);
  }
  console.log("data", data);

  if (!token.current) {
    throw new Error("Invalid Token");
  }
  if (!is_test_started) {
    return <div className="p-5 text-center">Assessment yet to start</div>;
  }

  if (is_test_ended) {
    return <div className="p-5 text-center">Assessment has ended</div>;
  }

  // if(data?.test_attempt?.is_completed){
  //   return <div className="p-5 text-center">You have already completed the assessment</div>;
  // }

  return (
    <div className="flex flex-col gap-2 p-5 text-base">
      {!hideTimer && (
        <Countdown
          autoStart={true}
          daysInHours={true}
          // onTick={({ total }) => {
          //   console.log("total", total);
          // }}
          renderer={({ formatted }) => {
            const { seconds } = formatted;

            return (
              <div className="flex items-center gap-3">
                <div className="w-5 h-5 spinner-grow" />
                <div className="font-medium">
                  All done. Ready to start? {seconds} secs
                </div>
              </div>
            );
          }}
          date={timerEnd}
          onComplete={() => {
            startAttempt({
              test_section_id: selected ?? data.test_sections[0]?.id,
            });
          }}
          onMount={({ completed }) => {
            if (completed) {
              startAttempt({
                test_section_id: selected ?? data.test_sections[0]?.id,
              });
            }
          }}
        />
      )}
      <div className="mb-5">
        Select the section you would like to start and click on start assessment
        button
      </div>
      {data.test_sections.map((section, i) => {
        const disabled = data.strict_section_order && i > 0;
        return (
          <button
            onClick={() => {
              if (!disabled) {
                setSelected(section.id);
              } else {
                if (section.attempt?.is_completed) {
                  toast("Section is already completed");
                } else if (data.strict_section_order && i > 0) {
                  toast("Complete previous sections to unlock this section");
                }
              }
            }}
            key={section.id}
            className={twMerge(
              "w-full border rounded-md bg-white flex items-stretch",
              section.attempt?.is_completed
                ? "border-2 border-green-600 cursor-not-allowed"
                : disabled
                ? "cursor-not-allowed"
                : ""
            )}
          >
            <div className="flex items-center flex-grow px-2 py-2 bg-slate-50">
              {section.attempt?.is_completed ? (
                <CheckBadgeIcon className="w-5 h-5 text-green-600" />
              ) : disabled ? (
                <LockClosedIcon className="w-5 h-5" />
              ) : (
                <input type="radio" checked={selected === section.id} />
              )}
            </div>
            <div className="flex flex-col items-start w-full px-5 py-2">
              <div>
                Section #{i + 1} -{" "}
                <span className="font-medium">{section.title}</span>
              </div>
              <div className="flex items-center gap-2">
                {[
                  {
                    label: "Questions",
                    value:
                      section.test_section_questions_aggregate.aggregate.count,
                  },
                  {
                    label: data.isPractice
                      ? "*Practice Mode"
                      : section.is_timed
                      ? "Minutes"
                      : "*Untimed section",
                    value:
                      section.is_timed && !data.isPractice
                        ? section.duration
                        : "",
                  },
                ].map((el, i) => {
                  return (
                    <>
                      {i > 0 && <div>|</div>}
                      <div className="flex items-center gap-1">
                        <span className="text-sm font-medium">{el.value}</span>
                        <span className="text-sm">{el.label}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* <td className="p-2">Section #{i + 1}</td>
          
            <td className="p-2">
              {el.is_timed ? `${el.duration} Minutes` : "*Untimed section"}{" "}
            </td> */}
          </button>
        );
      })}
      <button
        onClick={() => {
          if (selected) {
            startAttempt({
              test_section_id: selected,
            });
          } else {
            toast.error("Select a section first");
          }
        }}
        type="submit"
        className={twMerge(
          `w-full px-5 py-2 text-sm flex items-center justify-center text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
          submitting ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
        )}
        disabled={submitting}
      >
        {submitting ? <ArrowPathIcon className="w-5 h-5 animate-spin" /> : null}
        {submitting ? "Starting Assessment" : "Start Assessment"}
      </button>
      <div>
        <span>Untimed Section*</span>
        <span>
          * Untimed: These sections are without any specific time limit. You can
          answer these sections within the total assessment time limit. Total
          Time of Untimed Sections = Total time of test - Total time of Timed
          sections
        </span>
      </div>
    </div>
  );
}

export default SectionSelection;
