import { useAuth } from "@/components/AuthProvider";
import MarkdownRender from "@/components/markdown/MarkdownRender";
import CircularProgress from "@/components/ui/CircularProgress";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { CheckBadgeIcon, ClockIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import JSConfetti from "js-confetti";
import { useMemo } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiClipboard } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function AssessmentComplete() {
  const auth = useAuth();
  const { attempt_id } = useParams();
  const { isLoading, data, error } = useQuery(
    ["assessment", "attempt", "complete"],
    () =>
      fetch(
        `${FUNCTION_BASE_URL}/getAttemptCompletedData?attempt_id=${attempt_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token.current}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        }),
    {
      onSuccess: (data) => {
        if (data.is_completed) {
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti();
        }
      },
    }
  );

  const marksStats = useMemo(() => {
    if (data && !data.error) {
      return data.test_sections.reduce(
        (prev, cur) => {
          return cur.test_section_questions.reduce((prev2, cur2) => {
            return {
              max_marks: (prev2.max_marks ?? 0) + (cur2.max_marks ?? 0),
              marks_obtained:
                (prev2.marks_obtained ?? 0) + (cur2.marks_obtained ?? 0),
            };
          }, prev);
        },
        { max_marks: 0, marks_obtained: 0 }
      );
    }
    return {
      max_marks: 0,
      marks_obtained: 0,
    };
  }, [data]);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );
  }
  if (!data || data.error) {
    throw new Error(data?.error);
  }
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-5 p-5 text-white bg-indigo-500">
      <BsCheck2Circle className="w-48 h-48 text-green-600" />
      <div className="text-3xl text-center">
        {"Congratulations, You've completed your test"}
      </div>
      {data?.show_post_assessment_text && data?.post_assessment_text && (
        <div className="flex flex-col items-center w-full max-w-screen-sm gap-5">
          <div className="relative flex flex-col gap-2 px-5 pb-5 text-black bg-white border rounded-md shadow-md">
            <div className="absolute inset-x-0 flex justify-center w-full">
              <BiClipboard className="w-8 h-8 p-2 transform -translate-y-1/2 bg-white rounded-full" />
            </div>
            <MarkdownRender className="mt-5">
              {data?.post_assessment_text ?? ""}
            </MarkdownRender>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                window.open(
                  `https://${data.university.domain}/signInViaToken?token=${data.token}&tenantId=${data.university.tenantId}`,
                  "_self"
                );
              }}
              className="px-5 py-2 text-white bg-indigo-600 rounded-md"
            >
              Proceed
            </button>
          </div>
        </div>
      )}
      {data?.showStats && (
        <>
          <div className="flex justify-center w-full">
            <div className="flex flex-col items-center gap-3">
              <CircularProgress
                percent={
                  marksStats.max_marks === 0
                    ? 0
                    : (marksStats.marks_obtained / marksStats.max_marks) * 100
                }
                title={"Score"}
                value={`${marksStats.marks_obtained}/${marksStats.max_marks}`}
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <ClockIcon className="w-4 h-4 text-indigo-600" />
                  {format(new Date(data.created_at), "PPPpp")}
                </div>
                <div className="w-4 text-xs leading-none text-center">|</div>
                <div className="flex items-center gap-2">
                  <ClockIcon className="w-4 h-4 text-indigo-600" />
                  {format(new Date(data.completed_at), "PPPpp")}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full max-w-screen-md gap-5 mx-auto">
            {data.test_sections.map((test_section) => {
              return (
                <div
                  className="flex flex-col w-full bg-white border shadow"
                  key={test_section.id}
                >
                  <div className="flex flex-wrap justify-between px-5 py-2 bg-slate-50">
                    <div className="flex items-center gap-2">
                      {test_section.is_attempted ? (
                        <div>
                          <CheckBadgeIcon className="w-5 h-5 text-green-600" />
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="text-lg font-medium">
                        {test_section.title}
                      </div>
                    </div>
                    {test_section.is_attempted ? (
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <ClockIcon className="w-4 h-4 text-indigo-600" />
                          {format(new Date(test_section.created_at), "PPPpp")}
                        </div>
                        <div className="w-4 text-xs leading-none text-center">
                          |
                        </div>
                        <div className="flex items-center gap-2">
                          <ClockIcon className="w-4 h-4 text-indigo-600" />
                          {format(new Date(test_section.completed_at), "PPPpp")}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 px-5 py-2 bg-white">
                    {test_section.test_section_questions.map(
                      (test_section_question, i) => {
                        return (
                          <div
                            key={test_section_question.id}
                            className={twMerge(
                              "px-4 py-2 border bg-opacity-25 flex flex-col items-center",
                              test_section_question.status === "NA"
                                ? "border-yellow-600 bg-yellow-600"
                                : test_section_question.status ===
                                  "partial_correct"
                                ? "border-green-500 bg-green-500"
                                : test_section_question.status === "correct"
                                ? "border-green-800 bg-green-800"
                                : "border-red-600 bg-red-600"
                            )}
                          >
                            <span>#{i + 1}</span>
                            <span>
                              {test_section_question.marks_obtained}/
                              {test_section_question.max_marks}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
            <div className="flex flex-wrap w-full gap-5">
              <div className="flex items-center gap-2">
                <div
                  className={twMerge(
                    "px-2 py-1 border bg-opacity-25",
                    "border-yellow-600 bg-yellow-600"
                  )}
                >
                  #
                </div>
                <div>Skipped Question</div>
              </div>
              <div className="flex items-center gap-2">
                <div
                  className={twMerge(
                    "px-2 py-1 border bg-opacity-25",
                    "border-green-500 bg-green-500"
                  )}
                >
                  #
                </div>
                <div>Partially Correct Question</div>
              </div>
              <div className="flex items-center gap-2">
                <div
                  className={twMerge(
                    "px-2 py-1 border bg-opacity-25",
                    "border-green-800 bg-green-800"
                  )}
                >
                  #
                </div>
                <div>Correct Question</div>
              </div>
              <div className="flex items-center gap-2">
                <div
                  className={twMerge(
                    "px-2 py-1 border bg-opacity-25",
                    "border-red-600 bg-red-600"
                  )}
                >
                  #
                </div>
                <div>Incorrect Question</div>
              </div>
            </div>
          </div>
        </>
      )}

      {data?.test?.showStats && (
        <button
          onClick={() => {
            window.open(
              `https://${data.university.domain}/home/test/${data.id}`,
              "_self"
            );
          }}
          className="px-5 py-2 mt-5 text-white bg-indigo-600 rounded-md"
        >
          See full Stats
        </button>
      )}
    </div>
  );
}

export default AssessmentComplete;
