import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import App from "./App";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

LogRocket.init("8katby/assemewnbt");
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

Sentry.init({
  dsn: "https://de1522ac5d0846d85492216f7ab83cdc@o417993.ingest.us.sentry.io/4507396845404160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        /\bhttps?:\/\/[^\s<>"]+(?:\.\w+)+\.(?:aws|com)\b/g,
      ],
    }),
    Sentry.browserTracingIntegration(),

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function isLocalStorageSupported() {
  try {
    const testKey = "test";
    localStorage.setItem(testKey, "testValue");
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}
const root = ReactDOM.createRoot(document.getElementById("root"));
if (!isLocalStorageSupported()) {
  const ErrorComponent = () => (
    <div className="flex items-center justify-center w-full py-16 text-2xl font-semibold">
      <div className="max-w-md p-6 mx-auto mt-5 bg-white border border-red-500 rounded-lg shadow-xl shadow-outline-red ring-1 ring-red-400">
        <div className="w-10 h-10 text-red-400">
          <ExclamationTriangleIcon />
        </div>
        <div className="mt-4 mb-4">Unsupported Browser Detected</div>
        <div className="w-full mb-4 border-t border-gray-300" />
        <h2 className="mb-4 text-xl font-semibold">
          Instructions for Troubleshooting
        </h2>
        <p className="mb-4 text-sm">
          Your browser does not support localStorage, which is required for this
          application to function correctly.
        </p>
        <ol className="inline-block gap-2 text-sm text-left">
          <li className="py-2">Ensure that your browser is up to date. </li>
          <li className="py-2">
            If you are using private browsing or incognito mode, try using the
            regular browsing mode.
          </li>
          <li className="py-2">Clear your browser's cache and cookies.</li>
          <li className="py-1">
            Try using a different browser. We recommend the following:
            <ul>
              <li className="py-1">
                <p>Google Chrome</p>
              </li>

              <li>
                <p>Microsoft Edge</p>
              </li>
            </ul>
          </li>
        </ol>

        <div>
          <p className="py-4 text-base text-gray-600">
            If you continue to experience issues, please contact support.
          </p>
        </div>
      </div>
    </div>
  );
  root.render(<ErrorComponent />);
} else {
  root.render(
    // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    // </React.StrictMode>
  );
}

reportWebVitals();
