import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useAuth } from "./AuthProvider";
import ConfirmationModal from "./ui/ConfirmationModal";
import { toast } from "react-hot-toast";
import { CheckBadgeIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { useAssessment } from "./attempts/TestAttemptController";

function SectionSelection({ endSectionHandler, nextSectionLoading, onClose }) {
  const [selected, setSelected] = useState(null);
  const { token } = useAuth();
  const { id: test_id } = useAssessment();
  const { isLoading, error, data } = useQuery(
    ["assessment", "attempt_section"],
    () =>
      fetch(`${FUNCTION_BASE_URL}/getSectionsForSelection?test_id=${test_id}`, {
        headers: {
          Authorization: `Bearer ${token.current}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              "Network response was not ok " + res?.status + " " + res?.url
            );
          }
          return res.json();
        })
        .catch((error) => {
          console.error("Oops, we caught a promise rejection:", error);
          throw error;
        })
  );

  const next_section = useMemo(
    () => (data?.test_sections ?? [])?.find((el) => !el.attempt)?.id,
    [data]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }
  if (!data || data.error) {
    throw new Error(data?.error);
  }

  if (!token.current) {
    throw new Error("Invalid Token");
  }
  return (
    <div className="flex flex-col gap-5 p-5 text-base">
      {data.test_sections.map((section, i) => {
        const disabled = data.strict_section_order
          ? section.id !== next_section
          : !data.can_attempt_multiple
          ? section.attempt
            ? true
            : false
          : false;
        return (
          <button
            onClick={() => {
              if (disabled) {
                if (section.attempt?.is_completed) {
                  toast("Section is already completed");
                } else if (data.strict_section_order && i > 0) {
                  toast("Complete previous sections to unlock this section");
                } else if (
                  data.test_attempt?.current_section_id === section.id
                ) {
                  toast("You cannot choose the ongoing section");
                }
              } else {
                setSelected(section.id);
              }
            }}
            key={section.id}
            className={twMerge(
              "w-full border rounded-md bg-white flex flex-col items-stretch",
              section.attempt?.is_completed
                ? "border-2 border-green-600 cursor-not-allowed bg-gray-100 text-gray-500"
                : disabled
                ? "cursor-not-allowed bg-gray-100 text-gray-500"
                : "",
              data.test_attempt?.current_section_id === section.id
                ? "border-2 border-yellow-600"
                : ""
            )}
          >
            {(section.attempt?.is_completed || section.attempt) && (
              <div
                className={twMerge(
                  "py-1 w-full rounded-t-md text-white font-medium text-sm",
                  section.attempt?.is_completed
                    ? "bg-green-600 cursor-not-allowed"
                    : section.attempt
                    ? "bg-yellow-600"
                    : ""
                )}
              >
                {section.attempt?.is_completed ? (
                  <div>
                    Section Completed - Ended by {section.attempt.ending_method}
                  </div>
                ) : data.test_attempt?.current_section_id === section.id ? (
                  <div className="font-medium">Current section</div>
                ) : (
                  <div className="font-medium">Unfinished Section</div>
                )}
              </div>
            )}
            <div className="flex items-stretch">
              <div className="flex items-center px-2">
                {section.attempt?.is_completed ? (
                  <CheckBadgeIcon className="w-5 h-5 text-green-600" />
                ) : disabled ? (
                  <LockClosedIcon className="w-5 h-5" />
                ) : (
                  <input type="radio" checked={selected === section.id} />
                )}
              </div>

              <div className="flex items-center flex-grow bg-slate-50">
                <div className="flex flex-col items-start w-full px-5 py-2">
                  <div>
                    Section #{i + 1} -{" "}
                    <span className="font-medium">{section.title}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {[
                      {
                        label: "Questions",
                        value:
                          section.test_section_questions_aggregate.aggregate
                            .count,
                      },
                      {
                        label: data.isPractice
                          ? "*Practice Mode"
                          : section.is_timed
                          ? "Minutes"
                          : "*Untimed section",
                        value:
                          section.is_timed && !data.isPractice
                            ? section.duration
                            : "",
                      },
                    ].map((el, i) => {
                      return (
                        <>
                          {i > 0 && <div>|</div>}
                          <div className="flex items-center gap-1">
                            <span className="text-sm font-medium">
                              {el.value}
                            </span>
                            <span className="text-sm">{el.label}</span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </button>
        );
      })}
      <ConfirmationModal
        open={Boolean(selected)}
        onClose={() => setSelected(null)}
        onConfirm={async () => {
          endSectionHandler("user", selected);
        }}
        title={`Change Section`}
        message={`Changing the section will end the current section and start the new one. Once changed you cannot come back to this section. Do you wanna continue?`}
      />
      {!next_section ? (
        <button
          onClick={onClose}
          type="button"
          className={twMerge(
            `w-full px-5 py-2 text-sm flex items-center justify-center text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            nextSectionLoading
              ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
              : ""
          )}
          disabled={nextSectionLoading}
        >
          Close
        </button>
      ) : null}
      <div>
        <span>Untimed Section*</span>
        <span>
          * Untimed: These sections are without any specific time limit. You can
          answer these sections within the total assessment time limit. Total
          Time of Untimed Sections = Total time of test - Total time of Timed
          sections
        </span>
      </div>
    </div>
  );
}

export default SectionSelection;
