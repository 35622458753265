import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
// import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import yaml from "js-yaml";
import { generateEmbedURL } from "./../../utilities/generateEmbedURL";

function MarkdownCodeHighlighter({
  // node,
  // inline,
  className,
  children,
  ...props
}) {
  if (children?.length === 0) {
    return null;
  }
  const value = String(children).replace(/\n$/, "");
  const match = /language-(\w+)/.exec(className || "");
  try {
    switch (match?.[1] ?? "code") {
      case "audio":
        return (
          <div className="my-2">
            <audio controls controlsList="nodownload">
              <source src={yaml.load(value)?.src?.trim()} />
              Your browser does not support the audio tag.
            </audio>
          </div>
        );
      case "youtube":
        return (
          <div className="flex justify-center">
            <iframe
              title={"youtube"}
              src={generateEmbedURL({
                url: yaml.load(value)?.src?.trim(),
                type: "youtube",
              })}
              className="w-full h-48 sm:h-80 md:h-96 lg:h-110 xl:h-128"
              frameBorder="0"
              allow=" fullscreen; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        );
      case "vimeo":
        return (
          <div className="flex justify-center">
            <iframe
              title={"vimeo"}
              src={generateEmbedURL({
                url: yaml.load(value)?.src?.trim(),
                type: "vimeo",
              })}
              className="w-full h-48 sm:h-80 md:h-96 lg:h-110 xl:h-128"
              frameBorder="0"
              allow=" fullscreen; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        );

      case "code":
        return (
          <code className={className} {...props}>
            {children}
          </code>
        );

      default:
        console.log("match[1]", match[1]);
        return (
          <SyntaxHighlighter
            // style={docco}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {value}
          </SyntaxHighlighter>
        );
    }
  } catch (error) {
    console.log("error", error);
    return <div>Invalid YAML code in markdown...</div>;
  }
}

export default MarkdownCodeHighlighter;
