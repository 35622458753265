import { useQuery } from "@tanstack/react-query";
import app from "@/config/firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function SignInViaToken() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery(["getToken"], () =>
    fetch(`${FUNCTION_BASE_URL}/getToken?${window.location.search.slice(1)}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            "Network response was not ok " + res?.status + " " + res?.url
          );
        }
        return res.json();
      })
      .catch((error) => {
        console.error("Oops, we caught a promise rejection:", error);
        toast.error(
          "Your session has expired. Please close this window, log in again, and try."
        );
        throw new Error(error);
      })
  );

  useEffect(() => {
    if (data?.token) {
      const authApp = getAuth(app);
      authApp.tenantId = data.tenantId;
      localStorage.setItem("tenantId", data.tenantId);
      setLoading(true);
      authApp
        .signOut()
        .then(() => signInWithCustomToken(authApp, data.token))
        .then(() => {
          setLoading(false);
          navigate(`/welcome/${data.metadata.test_id}`);
        })
        .catch((error) => {
          console.error("Authentication error:", error);
          toast.error("Authentication failed. Please try again.");
          setLoading(false);
        });
    }
  }, [data, navigate]);

  if (isLoading || loading)
    return (
      <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );
  if (error) {
    const errorMessage =
      error?.message ?? "Something went wrong, Please reauthenticate yourself.";
    throw new Error(errorMessage);
  }

  if (!data || data.error) {
    throw new Error(data?.error);
  }

  return (
    <div>
      Unknown Error, If this issue persists, kindly Close this browser window as
      well , log out, and then sign back in before attempting to take the
      assessment again
    </div>
  );
}

export default SignInViaToken;
