import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
export default function PDFViewer({ url, showAll = false }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(false);
  const [scale, setScale] = useState(1);
  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // if (numPages < pageNumber)
    setPageNumber(1);
    setError(false);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <div className="flex flex-col items-center w-full gap-3 select-none">
      <div className="w-full overflow-x-auto">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(e) => {
            console.log(e);
            setError(true);
          }}
        >
          {Array(numPages)
            .fill()
            .map((_, i) => {
              return (
                <Page
                  className={`${
                    showAll || pageNumber === i + 1 ? "" : "hidden"
                  }`}
                  key={`pagenumber_${i + 1}`}
                  pageNumber={i + 1}
                  renderAnnotationLayer={false}
                  scale={scale}
                />
              );
            })}
        </Document>
      </div>
      {error ? null : (
        <div className="flex items-center justify-between w-full p-5 text-white bg-black rounded-md">
          <div className="flex items-center gap-2">
            <button
              className={`bg-black ${
                pageNumber <= 1 ? " cursor-not-allowed" : ""
              } px-1 py-1 rounded-md shadow-md text-white`}
              disabled={pageNumber <= 1}
              onClick={(e) => {
                e.preventDefault();
                setPageNumber(1);
              }}
            >
              {"<<"}
            </button>
            <button
              className={`bg-black ${
                pageNumber <= 1 ? " cursor-not-allowed" : ""
              } px-1 py-1 rounded-md shadow-md text-white`}
              disabled={pageNumber <= 1}
              onClick={(e) => {
                e.preventDefault();
                changePage(-1);
              }}
            >
              {"<"}
            </button>

            <button
              className={`bg-black ${
                pageNumber >= numPages
                  ? "group-hover:bg-gray-300 cursor-not-allowed"
                  : "group-hover:bg-white"
              } px-1 py-1 rounded-md shadow-md text-white`}
              disabled={pageNumber >= numPages}
              onClick={(e) => {
                e.preventDefault();
                changePage(1);
              }}
            >
              {">"}
            </button>
            <button
              className={`bg-black ${
                pageNumber >= numPages
                  ? "group-hover:bg-gray-300 cursor-not-allowed"
                  : "group-hover:bg-white"
              } px-1 py-1 rounded-md shadow-md text-white`}
              disabled={pageNumber >= numPages}
              onClick={(e) => {
                e.preventDefault();
                setPageNumber(numPages);
              }}
            >
              {">>"}
            </button>
            <div className="">
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </div>
          </div>
          <div className="w-40">
            <Slider
              name="completion_level"
              className="w-full ml-1"
              min={0.8}
              max={2}
              dots={false}
              step={0.1}
              value={scale}
              onChange={(value) => {
                console.log("value", value);
                setScale(value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
