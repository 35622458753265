import React, { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import SideOver from "../ui/SideOver";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import MarkdownRender from "../markdown/MarkdownRender";
import { MdClose } from "react-icons/md";

const tabs = [
  {
    name: "Test",
  },
  {
    name: "Section",
  },
];
function InstructionButton({ testInstruction, sectionInstruction }) {
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("Test");
  return (
    <div>
      <button onClick={() => setOpen(true)}>
        <BiInfoCircle className="w-4 h-4" />
      </button>
      <SideOver
        panelClassName={"max-w-screen-md"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
          <div className="flex items-center justify-between px-4 mt-5 sm:px-6 lg:px-8">
            <div className="text-2xl font-medium">Instructions</div>
            <button onClick={() => setOpen(false)}>
              <MdClose className="w-4 h-4" />
            </button>
          </div>

          <div className="mt-6 sm:mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
              <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      type="button"
                      key={tab.name}
                      onClick={() => {
                        setCurrentTab(tab.name);
                      }}
                      className={twMerge(
                        tab.name === currentTab
                          ? "border-pink-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="max-w-5xl px-4 mx-auto mt-6 sm:px-6 lg:px-8">
            {currentTab === "Test" && (
              <MarkdownRender>
                {testInstruction ?? "Not available"}
              </MarkdownRender>
            )}
            {currentTab === "Section" && (
              <MarkdownRender>
                {sectionInstruction ?? "Not available"}
              </MarkdownRender>
            )}
          </div>
        </main>
      </SideOver>
    </div>
  );
}

export default InstructionButton;
