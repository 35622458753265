import { Popover, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  ChevronDownIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { twMerge } from "tailwind-merge";

function QuestionStatPopover({
  currentSectionAttemptedQuestionsStats,
  questionData,
  currentSection,
  questions_map,
}) {
  if (!currentSection) {
    return null;
  }
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                flex items-center justify-center gap-2 px-5 py-1 text-xs text-center bg-white border rounded-b-full w-full`}
          >
            Attempted {currentSectionAttemptedQuestionsStats.attempted}/
            {currentSectionAttemptedQuestionsStats.total}
            <ChevronDownIcon className="w-4 h-4" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {/* <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform translate-x-full sm:translate-x-1/2 md:max-w-md md:translate-x-1/3 right-full sm:px-0 lg:max-w-3xl"> */}
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-2/3 sm:-translate-x-full left-full md:max-w-md sm:px-0 lg:max-w-3xl">
              <div className="p-5 bg-white border">
                <div className="flex flex-col gap-5 p-5 mt-6 sm:mt-2 2xl:mt-5">
                  <div className="flex flex-col gap-5">
                    <div className="flex items-start w-full gap-2">
                      <div>Progress</div>
                      <div className="flex flex-col items-center w-full py-2">
                        <div className="relative flex justify-center w-full h-2 border rounded-full">
                          <div
                            className="absolute left-0 h-full bg-green-600 rounded-full"
                            style={{
                              width: `${
                                currentSectionAttemptedQuestionsStats.total ===
                                0
                                  ? 0
                                  : (currentSectionAttemptedQuestionsStats.attempted /
                                      currentSectionAttemptedQuestionsStats.total) *
                                    100
                              }%`,
                            }}
                          />
                        </div>
                        <div>
                          {`${currentSectionAttemptedQuestionsStats.attempted}/${currentSectionAttemptedQuestionsStats.total}`}
                        </div>
                      </div>
                      {/* <div className="flex flex-col items-center gap-3">
                        <CircularProgress
                          percent={
                            questionStats.total === 0
                              ? 0
                              : (questionStats.attempted /
                                  questionStats.total) *
                                100
                          }
                          title={"Overview"}
                          value={`${questionStats.attempted}/${questionStats.total}`}
                        />
                      </div> */}
                    </div>
                    <div className="flex flex-col w-full max-w-screen-md gap-5 mx-auto">
                      <div
                        className="flex flex-col w-full bg-white border shadow"
                        key={currentSection.id}
                      >
                        <div className="flex flex-wrap justify-between px-5 py-2 bg-slate-50">
                          <div className="flex items-center gap-2">
                            {currentSection.test_attempt_sections[0]
                              ?.is_completed ? (
                              <div>
                                <CheckBadgeIcon className="w-5 h-5 text-green-600" />
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <div className="text-lg font-medium">Questions</div>
                          </div>
                          {currentSection.test_attempt_sections[0]
                            ?.is_completed ? (
                            <div className="flex flex-col">
                              <div className="flex items-center gap-2">
                                <ClockIcon className="w-4 h-4 text-indigo-600" />
                                {format(
                                  new Date(
                                    currentSection.test_attempt_sections[0]?.created_at
                                  ),
                                  "PPPpp"
                                )}
                              </div>
                              <div className="w-4 text-xs leading-none text-center">
                                |
                              </div>
                              <div className="flex items-center gap-2">
                                <ClockIcon className="w-4 h-4 text-indigo-600" />
                                {format(
                                  new Date(
                                    currentSection.test_attempt_sections[0]?.completed_at
                                  ),
                                  "PPPpp"
                                )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="flex flex-wrap gap-2 px-5 py-2 bg-white">
                          {questionData.test_section_questions.map((_, i) => {
                            const test_section_question =
                              questionData.test_section_questions[
                                questions_map[i]
                              ];
                            return (
                              <div
                                key={test_section_question.id}
                                className={twMerge(
                                  "px-4 py-2 border bg-opacity-25 flex flex-col items-center",
                                  test_section_question
                                    .test_question_submissions[0]?.is_marked
                                    ? "border-yellow-600 bg-yellow-600"
                                    : test_section_question
                                        .test_question_submissions[0]?.answer &&
                                      test_section_question
                                        .test_question_submissions[0].answer
                                        .answer !== ""
                                    ? "border-green-500 bg-green-500"
                                    : "border-red-600 bg-red-600"
                                )}
                              >
                                <span>#{i + 1}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="flex flex-wrap w-full gap-5">
                        <div className="flex items-center gap-2">
                          <div
                            className={twMerge(
                              "px-2 py-1 border bg-opacity-25",
                              "border-yellow-600 bg-yellow-600"
                            )}
                          >
                            #
                          </div>
                          <div>Marked Question</div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div
                            className={twMerge(
                              "px-2 py-1 border bg-opacity-25",
                              "border-green-500 bg-green-500"
                            )}
                          >
                            #
                          </div>
                          <div>Attempted Question</div>
                        </div>

                        <div className="flex items-center gap-2">
                          <div
                            className={twMerge(
                              "px-2 py-1 border bg-opacity-25",
                              "border-red-600 bg-red-600"
                            )}
                          >
                            #
                          </div>
                          <div>Not Attempted Question</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default QuestionStatPopover;
