import { useAuth } from "@/components/AuthProvider";
import FilePreviewModal from "@/components/attempts/questions/FilePreviewModal";
import { getFromS3, uploadToS3 } from "@/utilities/s3Helpers";
import { produce } from "immer";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { AiFillEye, AiFillFileText } from "react-icons/ai";
import { BsCloudUpload } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import Modal from "react-modal";

const FileUpload = forwardRef(
  (
    {
      test_id,
      attempt_id,
      test_section_question_id,
      fileConfig,
      setFileUrl,
      name,
      index,
    },
    componentRef
  ) => {
    // eslint-disable-next-line
    const [filePreview, setFilePreview] = useState(null);

    const { setValue, watch, control, reset, getValues } = useFormContext();

    const fields = watch(name) ?? [];
    const { remove, replace } = useFieldArray({
      name,
      control,
    });

    const [loadingPreview, setLoadingPreview] = useState(false);
    const { token } = useAuth();
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      accept: fileConfig.ext.map((el) => `.${el}`).join(","),
      multiple: true,
      maxFiles: (fileConfig.maxFiles ?? 2) - (fields?.length ?? 0),
      maxSize: (fileConfig.maxFileSize ?? 100) * 1024 * 1024,
      // onDrop: (files) => {
      //   console.log("files", files);
      // },
      onError: (error) => {
        console.log("error", error);
      },
      onDropAccepted: (acceptedFiles) => {
        if (
          acceptedFiles.length >
          (fileConfig.maxFiles ?? 2) - (fields?.length ?? 0)
        ) {
          toast.error(`Max allowed files is ${fileConfig.maxFiles ?? 2}`);
        } else {
          setLoading(true);

          const timestamp = new Date().getTime();
          const promise = Promise.all(
            acceptedFiles.map(async (file, index) => {
              const path = `file_submissions/${test_id}/${attempt_id}/${test_section_question_id}/${
                timestamp + index
              }-${file.name}`;
              try {
                const resData = await uploadToS3({
                  file,
                  path,
                  token: token.current,
                  type: file.type,
                });

                return {
                  file: resData.path,
                  ext: file.name?.split(".").pop(),
                  type: file.type,
                  name: file.name,
                };
              } catch (error) {
                console.log(error);
              }
            })
          );
          toast.promise(promise, {
            loading: "Uploading",
            success: "Uploaded",
            error: "Error when uploading",
          });
          promise
            .then((newFiles) => {
              setLoading(false);
              setFileUrl([...fields, ...newFiles.filter((el) => !!el)]);
            })
            .catch((error) => {
              console.log(error);
              toast.error("upload failed");
              setLoading(false);
            });
        }
      },
    });
    const [loading, setLoading] = useState(false);
    useImperativeHandle(componentRef, () => ({
      loading,
    }));
    return (
      <>
        <FilePreviewModal
          open={filePreview !== null}
          onClose={() => setFilePreview(null)}
          {...(filePreview ?? {})}
        />
        <div
          className={`flex ${fields.length === 0 ? "flex-col" : "gap-5"} py-3`}
        >
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-5">
              {(fields ?? []).map(({ file, name, ext }, i) => {
                const iext = ext?.toLowerCase() ?? "";

                return (
                  <div key={`file${i}`} className={`flex items-center gap-5`}>
                    <a
                      className={`flex items-center gap-3 py-4`}
                      href={file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillFileText className="w-6 h-6" />
                      <div
                        title={name}
                        style={{ maxWidth: "6rem" }}
                        className="truncate"
                      >
                        {name}
                      </div>
                    </a>
                    <div className="flex items-center justify-center gap-5">
                      <button
                        className={`flex items-center gap-3 py-4`}
                        onClick={async (e) => {
                          e.preventDefault();
                          try {
                            setLoadingPreview(true);
                            const resData = await getFromS3({
                              path: file,
                              token: token.current,
                            });
                            setFilePreview({
                              type: iext,
                              url: resData.url,
                              name,
                            });
                            setLoadingPreview(false);
                          } catch (error) {
                            setLoadingPreview(false);
                          }
                        }}
                      >
                        <AiFillEye className="w-6 h-6" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          reset(
                            produce(getValues(), (draft) => {
                              const files =
                                draft?.answer?.answer?.[index]?.files;
                              if (files) files.splice(i);
                            })
                          );
                        }}
                      >
                        <MdOutlineClose className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* {(fields ?? []).map((_, i) => {
              return (
              );
            })} */}
          </div>
          <div
            {...getRootProps({
              className: `text-gray-500 border mt-6 mb-4 ${
                (fileConfig.maxFiles ?? 2) - (fields?.length ?? 0) === 0
                  ? "hidden"
                  : ""
              } ${
                fields.length === 0 ? "px-8 py-16" : "px-3 py-1"
              }  bg-contain bg-center flex items-center bg-no-repeat border border-dashed border-black rounded-md text-center justify-center`,
            })}
          >
            <input {...getInputProps()} />
            {loading ? (
              <div className="w-6 h-6 mr-3 spinner-grow"></div>
            ) : fields?.length === 0 ? (
              <div className="flex flex-col items-center justify-center gap-3">
                <BsCloudUpload className="w-12 h-12 text-blue-500" />
                <p>
                  Drag {"'n'"} drop or click to select the file you want to
                  upload
                </p>
                <p className="text-blue-500">
                  <span>Only </span>
                  <span className="font-bold">
                    {fileConfig.ext?.join?.(", ") ?? fileConfig.ext}
                  </span>
                  <span> with max file size of </span>
                  <span className="font-bold">{fileConfig.maxFileSize}</span>
                  <span>MB are allowed</span>
                </p>
              </div>
            ) : (
              (fileConfig.maxFiles ?? 2) - (fields?.length ?? 0) > 0 && (
                <div className="flex items-center gap-2">
                  <BsCloudUpload className="w-5 h-5 text-blue-500" />
                  Add more
                </div>
              )
            )}
          </div>
        </div>
        {fileRejections.map(({ file, errors }) => (
          <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul className="text-red-600">
              {errors.map((e) => (
                <li key={e.code}>{e.message}</li>
              ))}
            </ul>
          </li>
        ))}
        <Modal
          isOpen={loading}
          onRequestClose={() => {}}
          htmlOpenClassName="overflow-hidden"
          bodyOpenClassName="overflow-hidden"
          closeTimeoutMS={300}
          className="inset-x-auto inset-y-auto max-h-full p-8 overflow-y-auto rounded-md focus:outline-none"
          overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-black bg-opacity-25 inset-0 fixed p-8"
        >
          <div className="flex items-center px-16 py-3 bg-white rounded-md">
            <div className="w-6 h-6 mr-3 spinner-grow"></div>
            <div>Uploading... Please wait</div>
          </div>
        </Modal>
        <Modal
          isOpen={loadingPreview}
          onRequestClose={() => {}}
          htmlOpenClassName="overflow-hidden"
          bodyOpenClassName="overflow-hidden"
          closeTimeoutMS={300}
          className="inset-x-auto inset-y-auto max-h-full p-8 overflow-y-auto rounded-md focus:outline-none"
          overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-black bg-opacity-25 inset-0 fixed p-8"
        >
          <div className="flex items-center px-16 py-3 bg-white rounded-md">
            <div className="w-6 h-6 mr-3 spinner-grow"></div>
            <div>Loading Preview... Please wait</div>
          </div>
        </Modal>
      </>
    );
  }
);

FileUpload.displayName = "FileUpload";
export default FileUpload;
