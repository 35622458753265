import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log("error, info", error, info);
    this.setState({
      error: error,
      errorInfo: info,
    });
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
  }

  render() {
    console.log("this.state", this.state);

    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <div className="flex items-center justify-center w-full py-16 text-2xl font-semibold">
            <div className="max-w-md p-6 mx-auto mt-5 bg-white border border-red-500 rounded-lg shadow-xl shadow-outline-red ring-1 ring-red-400">
              <div className="w-10 h-10 text-red-400">
                <ExclamationTriangleIcon />
              </div>
              <div className="mt-4 mb-4">Session Invalid</div>
              <div className="w-full mb-4 border-t border-gray-300" />
              <h2 className="mb-4 text-xl font-semibold">
                Instructions for Troubleshooting
              </h2>

              <div className="mb-4">
                <h3 className="mb-2 text-xl font-semibold">
                  1) Invalid Session Detected
                </h3>
                <p className="mb-2 text-sm">
                  Your assessment session has been detected as invalid. Please
                  close the window and log in again to continue.
                </p>
              </div>

              <div className="mb-4">
                <h3 className="mb-2 text-xl font-semibold">
                  2) Close Other Browser Windows
                </h3>
                <p className="mb-2 text-sm">
                  Please close all other assessment browser windows/tabs.
                </p>
                <p className="mb-2 text-sm">
                  Please note that only one assessment browser tab can be active
                  at a time. Kindly close all other assessment tabs and refresh
                  this page to proceed. If this issue persists, kindly Close
                  this browser window as well , log out, and then sign back in
                  before attempting to take the assessment again
                </p>
              </div>

              <div>
                <p className="mb-3 text-base text-gray-600">
                  If this issue still persists, clear the browser cache and try
                  again.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <pre className="font-mono text-sm text-wrap">
                  Error: {error?.toString()}
                  <br />
                  Stack trace: {componentStack}
                </pre>
              </div>
            </div>
          </div>
        )}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
