import { CheckBadgeIcon, ClockIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import CircularProgress from "../ui/CircularProgress";
import ConfirmationModal from "../ui/ConfirmationModal";
import SideOver from "../ui/SideOver";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../AuthProvider";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import randomSeed from "random-seed";
import { useAssessment, useAttempt } from "./TestAttemptController";

function EndAssessmentSideOver({ open, onClose, onConfirm }) {
  const [confirm, setConfirm] = useState(false);
  const { randomize_questions } = useAssessment();
  const { id: attempt_id } = useAttempt();
  const auth = useAuth();
  const { isLoading, data } = useQuery(
    ["assessment", "attempt", "overview"],
    () =>
      fetch(
        `${FUNCTION_BASE_URL}/assessmentOverview?attempt_id=${attempt_id}`,
        {
          headers: {
            Authorization: auth.token.current
              ? `Bearer ${auth.token.current}`
              : null,
          },
        }
      ).then((res) => res.json()).catch(error => {
        console.error('Oops, we caught a promise rejection:', error);
throw new Error(error);
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const questionStats = useMemo(() => {
    if (!data || data.error) {
      return { attempted: 0, total: 0 };
    } else {
      return data.tests_by_pk.test_sections.reduce(
        (p, test_section) => {
          return test_section.test_section_questions.reduce((prev, cur) => {
            return {
              attempted:
                prev.attempted +
                (cur.test_question_submissions[0]?.answer &&
                cur.test_question_submissions[0].answer.answer !== ""
                  ? 1
                  : 0),
              total: prev.total + 1,
            };
          }, p);
        },
        {
          attempted: 0,
          total: 0,
        }
      );
    }
  }, [data]);
  const questionMaps = useMemo(() => {
    if (data) {
      return data.tests_by_pk.test_sections.reduce((p, test_section) => {
        const question_count = test_section.test_section_questions.length;
        const question_indexes = Array(question_count)
          .fill()
          .map((_, i) => i);

        if (randomize_questions) {
          const math = randomSeed.create(attempt_id);
          question_indexes.sort(() => 0.5 - math.random());
        }
        return {
          ...p,
          [test_section.id]: question_indexes.reduce((prev, cur, i) => {
            return {
              ...prev,
              [i]: cur,
            };
          }, {}),
        };
      }, {});
    }
    return {};
  }, [data, attempt_id, randomize_questions]);

  return (
    <SideOver panelClassName={"max-w-screen-md"} open={open} onClose={onClose}>
      <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
        <div className="flex items-center justify-between px-4 mt-5 sm:px-6 lg:px-8">
          <div className="text-2xl font-medium">End Assessment</div>
          <button onClick={onClose}>
            <MdClose className="w-4 h-4" />
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center py-16">
            <div>Loading...</div>
          </div>
        ) : !data ? (
          <div className="flex justify-center py-16">
            <div>Error...</div>
          </div>
        ) : (
          <div className="flex flex-col gap-5 p-5 mt-6 sm:mt-2 2xl:mt-5">
            <div className="flex flex-col gap-5">
              <div className="flex justify-center w-full">
                <div className="flex flex-col items-center gap-3">
                  <CircularProgress
                    percent={
                      questionStats.total === 0
                        ? 0
                        : (questionStats.attempted / questionStats.total) * 100
                    }
                    title={"Overview"}
                    value={`${questionStats.attempted}/${questionStats.total}`}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full max-w-screen-md gap-5 mx-auto">
                {data.tests_by_pk.test_sections.map((test_section) => {
                  return (
                    <div
                      className="flex flex-col w-full bg-white border shadow"
                      key={test_section.id}
                    >
                      <div className="flex flex-wrap justify-between px-5 py-2 bg-slate-50">
                        <div className="flex items-center gap-2">
                          {test_section.test_attempt_sections[0]
                            ?.is_completed ? (
                            <div>
                              <CheckBadgeIcon className="w-5 h-5 text-green-600" />
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <div className="text-lg font-medium">
                            {test_section.title}
                          </div>
                          {test_section.test_attempt_sections[0]
                            ?.is_completed && (
                            <div>
                              (Ended by{" "}
                              {
                                test_section.test_attempt_sections[0]
                                  ?.ending_method
                              }
                              )
                            </div>
                          )}
                        </div>
                        {test_section.test_attempt_sections[0]?.is_completed ? (
                          <div className="flex flex-col">
                            <div className="flex items-center gap-2">
                              <ClockIcon className="w-4 h-4 text-indigo-600" />
                              {format(
                                new Date(
                                  test_section.test_attempt_sections[0]?.created_at
                                ),
                                "PPPpp"
                              )}
                            </div>
                            <div className="w-4 text-xs leading-none text-center">
                              |
                            </div>
                            <div className="flex items-center gap-2">
                              <ClockIcon className="w-4 h-4 text-indigo-600" />
                              {format(
                                new Date(
                                  test_section.test_attempt_sections[0]?.completed_at
                                ),
                                "PPPpp"
                              )}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="flex flex-wrap gap-2 px-5 py-2 bg-white">
                        {test_section.test_section_questions.map((_, i) => {
                          const test_section_question =
                            test_section.test_section_questions[
                              questionMaps[test_section.id][i]
                            ];
                          return (
                            <div
                              key={test_section_question.id}
                              className={twMerge(
                                "px-4 py-2 border bg-opacity-25 flex flex-col items-center",
                                test_section_question
                                  .test_question_submissions[0]?.is_marked
                                  ? "border-yellow-600 bg-yellow-600"
                                  : test_section_question
                                      .test_question_submissions[0]?.answer &&
                                    test_section_question
                                      .test_question_submissions[0].answer
                                      .answer !== ""
                                  ? "border-green-500 bg-green-500"
                                  : "border-red-600 bg-red-600"
                              )}
                            >
                              <span>#{i + 1}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <div className="flex flex-wrap w-full gap-5">
                  <div className="flex items-center gap-2">
                    <div
                      className={twMerge(
                        "px-2 py-1 border bg-opacity-25",
                        "border-yellow-600 bg-yellow-600"
                      )}
                    >
                      #
                    </div>
                    <div>Marked Question</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div
                      className={twMerge(
                        "px-2 py-1 border bg-opacity-25",
                        "border-green-500 bg-green-500"
                      )}
                    >
                      #
                    </div>
                    <div>Attempted Question</div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div
                      className={twMerge(
                        "px-2 py-1 border bg-opacity-25",
                        "border-red-600 bg-red-600"
                      )}
                    >
                      #
                    </div>
                    <div>Not Attempted Question</div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="px-5 py-1 text-white bg-indigo-600 border rounded-md"
              onClick={() => {
                setConfirm(true);
              }}
            >
              Finish Test
            </button>
          </div>
        )}
        <ConfirmationModal
          open={confirm}
          onClose={() => setConfirm(false)}
          onConfirm={onConfirm}
          title={`End Assessment`}
          message={`Are you sure you wanna end the assessment?`}
        />
      </main>
    </SideOver>
  );
}

export default EndAssessmentSideOver;
