import { useRef } from "react";
import { forwardRef, useImperativeHandle, useState } from "react";

const TimerVideo = forwardRef(({ children }, ref) => {
  const [elapsed, setElapsed] = useState(0);

  const timerRef = useRef();

  const resetTimer = () => {
    clearInterval(timerRef.current);
    setElapsed(0);
  };
  const pauseTimer = () => {
    clearInterval(timerRef.current);
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => setElapsed((x) => x + 1), 1000);
  };

  const restartTimer = () => {
    setElapsed(0);
    startTimer();
  };

  useImperativeHandle(ref, () => ({
    elapsed,
    restartTimer,
    startTimer,
    pauseTimer,
    resetTimer,
  }));
  return children ? children(elapsed) : null;
});

TimerVideo.displayName = "TimerVideo";

export default TimerVideo;
